import DBModel from './DBModel';
import DataUtils from '../../utils/DataUtils';
import WaveModel from './WaveModel';
import DataModel from './DataModel';
import AssetModel from './AssetModel';
import SkillModel from './SkillModel';
import ModifierModel from './ModifierModel';
import TypeClassModel from './TypeClassModel';
import ModifierGroupModel from './ModifierGroupModel';

export default class LevelModel extends DBModel {
    constructor({
        name,
        descriptionKey,
        gameMode,
        priority,
        startingGold,
        initialPlayerIncome,
        initialHP,
        initialHPRegen,
        incomeIncreaseAmount,
        incomeIncreasePerSeconds,
        loopMultiplier,
        loops,
        modifiers,
        pool,
        shop,
        waves,
        showcaseAssets,
        damageMultiplierRules,
        ...attrs
    }) {
        super(attrs);
        Object.assign(this, {
            name,
            descriptionKey,
            gameMode,
            priority,
            startingGold,
            initialPlayerIncome,
            initialHP,
            initialHPRegen,
            incomeIncreaseAmount,
            incomeIncreasePerSeconds,
            loopMultiplier,
            loops,
        });
        this.modifiers = (modifiers ?? []).map(
            (modifier) => new ModifierModel(modifier)
        );
        this.pool = new PoolModel(pool);
        this.shop = new ShopModel(shop);
        this.waves = (waves ?? []).map((wave) => new WaveModel(wave));
        this.showcaseAssets = (showcaseAssets ?? []).map(
            (asset) => new AssetModel(asset)
        );
        this.damageMultiplierRules = (damageMultiplierRules ?? []).map(
            (damageMultiplierRule) =>
                new DamageMultiplierRuleModel(damageMultiplierRule)
        );
    }

    static createDTO({
        name,
        descriptionKey,
        gameMode,
        priority,
        initialGold,
        initialIncome,
        initialHP,
        initialHPRegen,
        incomeIncreasePerSeconds,
        incomeIncreaseAmount,
        shop,
        modifierIds,
        modifierGroupPool,
        modifierPool,
        skillPool,
        showcaseAssetIds,
        waves,
    }) {
        return {
            name: DataUtils.setString(name),
            descriptionKey: DataUtils.setString(descriptionKey),
            gameMode: DataUtils.setString(gameMode),
            priority: DataUtils.setInt(priority),
            initialGold: DataUtils.setFloat(initialGold),
            initialIncome: DataUtils.setFloat(initialIncome),
            initialHP: DataUtils.setFloat(initialHP),
            initialHPRegen: DataUtils.setFloat(initialHPRegen),
            incomeIncreasePerSeconds: DataUtils.setFloat(
                incomeIncreasePerSeconds
            ),
            incomeIncreaseAmount: DataUtils.setFloat(incomeIncreaseAmount),
            shop: ShopModel.createDTO(shop),
            modifierIds: DataUtils.setList(modifierIds, DataUtils.setInt),
            modifierGroupPool: DataUtils.setList(
                modifierGroupPool,
                DataUtils.setInt
            ),
            modifierPool: DataUtils.setList(modifierPool, DataUtils.setInt),
            skillPool: DataUtils.setList(skillPool, DataUtils.setInt),
            showcaseAssetIds: DataUtils.setList(
                showcaseAssetIds,
                DataUtils.setInt
            ),
            waves: DataUtils.setList(waves, WaveModel.createDTO),
        };
    }

    static titles = {
        ...super.titles,
        name: 'Name',
        descriptionKey: 'Desc. Key',
        gameMode: 'Game Mode',
        priority: 'Priority',
        startingGold: 'Starting Gold',
        initialPlayerIncome: 'Initial Player Income',
        initialHP: 'Initial HP',
        initialHPRegen: 'Initial HP Regen',
        incomeIncreasePerSeconds: 'Income Inc. Per Second',
        incomeIncreaseAmount: 'Income Inc. Amount',
        loopMultiplier: 'Loop Multiplier',
        loops: 'Loops',
        shop: 'Shop',
        modifiers: 'Modifiers',
        pool: 'Pool',
        damageMultiplierRules: 'Damage Multiplier Rules',
        showcaseAssets: 'Showcase Assets',
        waves: 'Waves',
    };
}

export class DamageMultiplierRuleModel extends DataModel {
    constructor({ from, to, multiplier, ...attrs }) {
        super(attrs);
        this.multiplier = multiplier;
        this.from = from && new TypeClassModel(from);
        this.to = to && new TypeClassModel(to);
    }

    static titles = {
        ...super.titles,
        from: 'From',
        to: 'To',
        multiplier: 'Multiplier',
    };

    static createDTO({ fromId, toId, multiplier }) {
        return {
            fromId: DataUtils.setInt(fromId),
            toId: DataUtils.setInt(toId),
            multiplier: DataUtils.setFloat(multiplier),
        };
    }
}

class PoolModel extends DataModel {
    constructor({ name, modifierGroupPool, skillPool, ...attrs }) {
        super(attrs);
        this.name = name;
        this.skillPool = (skillPool ?? []).map(
            (skill) => new SkillPoolModel(skill)
        );
        this.modifierGroupPool = (modifierGroupPool ?? []).map(
            (modifierGroup) => new ModifierGroupPoolModel(modifierGroup)
        );
    }

    static titles = {
        ...super.titles,
        name: 'Name',
        skillPool: 'Skill Pool',
        modifierGroupPool: 'Modifier Group Pool',
    };
}

class SkillPoolModel extends DataModel {
    constructor({ skill, count, ...attrs }) {
        super(attrs);
        this.count = count;
        this.skill = skill && new SkillModel(skill);
    }

    static titles = {
        ...super.titles,
        skill: 'Skill',
        count: 'Count',
    };
}

class ModifierGroupPoolModel extends DataModel {
    constructor({ modifierGroup, count, ...attrs }) {
        super(attrs);
        this.count = count;
        this.modifierGroup =
            modifierGroup && new ModifierGroupModel(modifierGroup);
    }

    static titles = {
        ...super.titles,
        modifierGroup: 'Modifier Group',
        count: 'Count',
    };
}

class ShopModel extends DataModel {
    constructor({
        goldIncreasePerReRoll,
        initialFreeReRollAmount,
        initialReRollCost,
        initialReRollCount,
        reRollCostIncreasePerReRoll,
        reRollCountIncreaseAmount,
        reRollCountIncreaseTimeInSeconds,
        resetTimeInSeconds,
        ...attrs
    }) {
        super(attrs);
        Object.assign(this, {
            goldIncreasePerReRoll,
            initialFreeReRollAmount,
            initialReRollCost,
            initialReRollCount,
            reRollCostIncreasePerReRoll,
            reRollCountIncreaseAmount,
            reRollCountIncreaseTimeInSeconds,
            resetTimeInSeconds,
        });
    }

    static titles = {
        ...super.titles,
        goldIncreasePerReRoll: 'Gold Inc. Per Re-roll',
        initialFreeReRollAmount: 'Initial Free Re-roll Amount',
        initialReRollCost: 'Initial Re-roll Cost',
        initialReRollCount: 'Initial Re-roll Count',
        reRollCostIncreasePerReRoll: 'Re-roll Cost Inc. Per Re-roll',
        reRollCountIncreaseAmount: 'Re-roll Count Inc. Amount',
        reRollCountIncreaseTimeInSeconds: 'Re-roll Count Inc. Time In Seconds',
        resetTimeInSeconds: 'Reser Time In Seconds',
    };

    static createDTO({
        resetTimeInSeconds,
        goldIncreasePerReRoll,
        initialReRollCount,
        initialReRollCost,
        initialFreeReRolls,
        reRollCountIncreaseTimeInSeconds,
        reRollCountIncreaseAmount,
        reRollCostIncreasePerReRoll,
    }) {
        return {
            resetTimeInSeconds: DataUtils.setFloat(resetTimeInSeconds),
            goldIncreasePerReRoll: DataUtils.setFloat(goldIncreasePerReRoll),
            initialReRollCount: DataUtils.setInt(initialReRollCount),
            initialReRollCost: DataUtils.setFloat(initialReRollCost),
            initialFreeReRolls: DataUtils.setInt(initialFreeReRolls),
            reRollCountIncreaseTimeInSeconds: DataUtils.setFloat(
                reRollCountIncreaseTimeInSeconds
            ),
            reRollCountIncreaseAmount: DataUtils.setInt(
                reRollCountIncreaseAmount
            ),
            reRollCostIncreasePerReRoll: DataUtils.setFloat(
                reRollCostIncreasePerReRoll
            ),
        };
    }
}
