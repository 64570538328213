import axios from 'axios';

import AuthService from './AuthService';

export default class LevelService {
    static async createLevel(data) {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.post('/level', data);
        return response;
    }

    static async getAllLevels() {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.get('/levels');
        return response;
    }
}
