import React, { useContext, useEffect, useState } from 'react';

import Modal from '../../../common/modal/Modal';
import Drawer from '../../../common/drawer/Drawer';
import styles from './TypeClassFormModal.module.css';
import Gallery from '../../../common/gallery/Gallery';
import Dropdown from '../../../common/dropdown/Dropdown';
import EnumUtils from '../../../../utils/EnumUtils';
import TextInput from '../../../common/text_input/TextInput';
import AssetUtils from '../../../../utils/AssetUtils';
import ColorInput from '../../../common/color_input/ColorInput';
import StateContext from '../../../../providers/StateProvider';
import StateConstants from '../../../../constants/StateConstants';

export default function TypeClassFormModal(props) {
    // States
    const { state, setState } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const typeClassStates = StateConstants.TypeClassStates;
    const enumStates = StateConstants.EnumStates;
    const [searchKey, setSearchKey] = useState('');
    const [assetType, setAssetType] = useState('');

    // Functions
    const handleAssetSelection = (assetId) => {
        if (state[typeClassStates.ASSET_IDS].includes(assetId)) {
            setState(
                typeClassStates.ASSET_IDS,
                state[typeClassStates.ASSET_IDS].filter((id) => id !== assetId)
            );
        } else {
            const assetSelection = state[commonStates.ASSETS].find(
                (asset) => asset.id === assetId
            );
            const selectedAssets = state[commonStates.ASSETS].filter((asset) =>
                state[typeClassStates.ASSET_IDS].includes(asset.id)
            );
            const selectedAssetTypes = selectedAssets.map(
                (asset) => asset.assetType
            );
            const filteredAssetIds = selectedAssetTypes.includes(
                assetSelection.assetType
            )
                ? selectedAssets
                      .filter(
                          (asset) =>
                              asset.assetType !== assetSelection.assetType
                      )
                      .map((asset) => asset.id)
                : state[typeClassStates.ASSET_IDS];
            setState(typeClassStates.ASSET_IDS, [...filteredAssetIds, assetId]);
        }
    };

    // Startup
    useEffect(() => {
        setState(StateConstants.AssetStates.IMAGE, undefined);
        // eslint-disable-next-line
    }, []);

    return (
        <Modal
            title={
                props.mode === 'edit' ? 'Edit Type Class' : 'Create Type Class'
            }
            visible={props.visible}
            setVisible={props.setVisible}
        >
            <div className={styles.form_modal}>
                <TextInput
                    label="Type Class Name (String)"
                    value={state[typeClassStates.NAME]}
                    onChange={(value) => setState(typeClassStates.NAME, value)}
                />
                <TextInput
                    label="Description Key (String)"
                    value={state[typeClassStates.DESC_KEY]}
                    onChange={(value) =>
                        setState(typeClassStates.DESC_KEY, value)
                    }
                />
                <ColorInput
                    label="Colour"
                    value={state[typeClassStates.COLOUR]}
                    onChange={(value) =>
                        setState(typeClassStates.COLOUR, value)
                    }
                />
                <Drawer
                    label="Assets"
                    text={
                        state[typeClassStates.ASSET_IDS].length > 0
                            ? `Selected ${
                                  state[typeClassStates.ASSET_IDS].length
                              } asset(s).`
                            : 'Not selected.'
                    }
                >
                    <div className="flex_column full_width padding">
                        <div
                            className="flex_row full_width"
                            style={{ flexWrap: 'wrap' }}
                        >
                            <div
                                style={{ minWidth: '200px', maxWidth: '200px' }}
                            >
                                <Dropdown
                                    id="assetFilterType"
                                    label="Asset Type Filter"
                                    onChange={setAssetType}
                                    defaultValue=""
                                >
                                    <option key="" value="">
                                        All
                                    </option>
                                    {EnumUtils.createTitleMapping(
                                        state[enumStates.ASSET_TYPES]
                                    ).map(({ title, value }) => (
                                        <option key={value} value={value}>
                                            {title}
                                        </option>
                                    ))}
                                </Dropdown>
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    minWidth: '200px',
                                    maxWidth: '400px',
                                }}
                            >
                                <TextInput
                                    label="Search Filter"
                                    onChange={setSearchKey}
                                />
                            </div>
                        </div>
                        <Gallery
                            loading={state[commonStates.LOADING_ASSETS]}
                            data={AssetUtils.createGalleryData(
                                state[commonStates.ASSETS],
                                {
                                    searchKey: searchKey,
                                    assetType: assetType,
                                    selectedIds:
                                        state[typeClassStates.ASSET_IDS],
                                }
                            )}
                            selections={state[typeClassStates.ASSET_IDS]}
                            onClick={handleAssetSelection}
                            selectionsFirst
                        />
                    </div>
                </Drawer>
                <div className="page_row right">
                    <button className="solid" onClick={props.createTypeClass}>
                        Create Type Class
                    </button>
                </div>
            </div>
        </Modal>
    );
}
