import DBModel from './DBModel';
import DataUtils from '../../utils/DataUtils';

export default class AssetModel extends DBModel {
    constructor({ assetType, assetReference, imageUrl, ...attr }) {
        super(attr);
        Object.assign(this, {
            assetType,
            assetReference,
            imageUrl,
        });
    }

    static titles = {
        ...super.titles,
        assetType: 'Type',
        assetReference: 'Reference',
        imageUrl: 'Image URL',
    };

    static primitiveKeys = [
        ...super.primitiveKeys,
        'assetReference',
        'assetType',
    ];

    static async createDTO({ assetType, assetReference, image }) {
        return {
            assetType: DataUtils.setString(assetType),
            assetReference: DataUtils.setString(
                assetReference && assetType && `${assetReference}-${assetType}`
            ),
            image: await DataUtils.setFile(image),
        };
    }
}
