import Pill from '../pill/Pill';
import styles from './Card.module.css';
import Gallery from '../gallery/Gallery';
import LabelWrapper from '../label_wrapper/LabelWrapper';

export default function Card(props) {
    const cardClassName = `${styles.card} ${
        props.borderColor ? styles.border : ''
    }`.trim();

    const loadingCardClassName = `${styles.card} ${styles.skeleton} ${
        props.last ? styles.last : ''
    }`.trim();

    return props.loading ? (
        <div className={loadingCardClassName}></div>
    ) : (
        <div
            className={cardClassName}
            style={{
                '--width': props.width && `${props.width}px`,
                borderColor: props.borderColor,
            }}
        >
            {props.toolbar && (
                <div className={styles.toolbar}>{props.toolbar}</div>
            )}
            {(props.title || props.label) && (
                <div className={styles.header}>
                    {props.title && (
                        <Pill
                            text={props.title}
                            color={props.color}
                            onClick={props.onClick}
                        />
                    )}
                    {props.label && <Pill text={props.label} circular />}
                </div>
            )}
            {props.sections &&
                props.sections.map(({ type, title, content }, index) => (
                    <CardSection
                        key={index}
                        type={type}
                        title={title}
                        content={content}
                    />
                ))}
        </div>
    );
}

function CardSection({ type, title, content }) {
    return type === 'gallery' ? (
        <LabelWrapper label={title}>
            <Gallery
                data={content.data}
                highlight={content.highlight}
                columnWidth={50}
            />
        </LabelWrapper>
    ) : type === 'json' ? (
        <LabelWrapper label={title}>
            <div className={styles.card_sub_section}>
                {content.data.map(({ type, title, content }, index) => (
                    <CardSection
                        key={index}
                        type={type}
                        title={title}
                        content={content}
                    />
                ))}
            </div>
        </LabelWrapper>
    ) : type === 'value' ? (
        <p className={styles.card_data}>
            <span className={styles.title}>{title}:</span> {content.value}
        </p>
    ) : undefined;
}
