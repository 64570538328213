import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { NotificationProvider } from './providers/NotificationProvider';
import { StateProvider } from './providers/StateProvider';
import App from './App';

import './styles/index.css';
import './styles/common.css';
import './styles/constants.css';
import './styles/theme.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <StateProvider>
            <NotificationProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </NotificationProvider>
        </StateProvider>
    </React.StrictMode>
);
