import DBModel from './DBModel';
import DataUtils from '../../utils/DataUtils';
import AssetModel from './AssetModel';
import TypeClassModel from './TypeClassModel';

export default class BaseAttackModel extends DBModel {
    constructor({
        projectileAngle,
        projectileSpeed,
        range,
        speed,
        damage,
        attackType,
        damageType,
        assets,
        ...attrs
    }) {
        super(attrs);
        Object.assign(this, {
            projectileAngle,
            projectileSpeed,
            range,
            speed,
            damage,
            attackType,
        });
        this.assets = (assets ?? []).map((asset) => new AssetModel(asset));
        this.damageType = damageType && new TypeClassModel(damageType);
    }

    static titles = {
        ...super.titles,
        projectileAngle: 'Projectile Angle',
        projectileSpeed: 'Projectile Speed',
        range: 'Range',
        speed: 'Speed',
        damage: 'Damage',
        attackType: 'Attack Type',
        damageType: 'Damage Type',
        assets: 'Assets',
    };

    static createDTO({
        projectileAngle,
        projectileSpeed,
        range,
        speed,
        damage,
        attackType,
        damageTypeId,
        assetIds,
    }) {
        return {
            projectileSpeed: DataUtils.setFloat(projectileSpeed),
            projectileAngle: DataUtils.setFloat(projectileAngle),
            range: DataUtils.setFloat(range),
            speed: DataUtils.setFloat(speed),
            damage: DataUtils.setFloat(damage),
            attackType: DataUtils.setString(attackType),
            damageTypeId: DataUtils.setInt(damageTypeId),
            assetIds: DataUtils.setList(assetIds, DataUtils.setInt),
        };
    }
}
