import CardModel from '../models/component_models/CardModel';
import TypeClassModel from '../models/data_models/TypeClassModel';

export default class TierUtils {
    static createCardData(dictObj) {
        const obj = new TypeClassModel(dictObj);
        return new CardModel({
            id: obj.id,
            title: obj.name,
            color: obj.colour,
            width: 200,
            sections: [
                {
                    type: 'value',
                    title: obj.titles.id,
                    content: {
                        value: obj.id.toString(),
                    },
                },
            ],
        });
    }

    static createCardListData(tiers) {
        return tiers.map((dictItem) => this.createCardData(dictItem));
    }
}
