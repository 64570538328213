import React, { useContext, useEffect } from 'react';

import Modal from '../../../common/modal/Modal';
import styles from './AssetFormModal.module.css';
import Dropdown from '../../../common/dropdown/Dropdown';
import EnumUtils from '../../../../utils/EnumUtils';
import FileInput from '../../../common/file_input/FileInput';
import TextInput from '../../../common/text_input/TextInput';
import StateContext from '../../../../providers/StateProvider';
import StateConstants from '../../../../constants/StateConstants';

export default function AssetFormModal(props) {
    // States
    const { state, setState } = useContext(StateContext);
    const assetStates = StateConstants.AssetStates;
    const enumStates = StateConstants.EnumStates;

    // Startup
    useEffect(() => {
        setState(StateConstants.AssetStates.IMAGE, undefined);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (state[enumStates.ASSET_TYPES] && !state[assetStates.TYPE]) {
            setState(assetStates.TYPE, state[enumStates.ASSET_TYPES][0]);
        }
        // eslint-disable-next-line
    }, [state[enumStates.ASSET_TYPES]]);

    return (
        <Modal
            title={props.mode === 'edit' ? 'Edit Asset' : 'Create Asset'}
            visible={props.visible}
            setVisible={props.setVisible}
        >
            <div className={styles.form_modal}>
                <Dropdown
                    value={state[assetStates.TYPE]}
                    label="Asset Type"
                    onChange={(value) =>
                        setState(StateConstants.AssetStates.TYPE, value)
                    }
                >
                    {EnumUtils.createTitleMapping(
                        state[enumStates.ASSET_TYPES]
                    ).map(({ title, value }, index) => (
                        <option key={index} value={value}>
                            {title}
                        </option>
                    ))}
                </Dropdown>
                <TextInput
                    label="Asset Reference (String)"
                    value={state[assetStates.REFERENCE]}
                    onChange={(value) =>
                        setState(StateConstants.AssetStates.REFERENCE, value)
                    }
                    suffix={
                        state[assetStates.TYPE]
                            ? `-${state[assetStates.TYPE]}`
                            : '-suffix'
                    }
                />
                <FileInput
                    label="Asset Image"
                    value={state[assetStates.IMAGE]}
                    onChange={(value) =>
                        setState(StateConstants.AssetStates.IMAGE, value)
                    }
                />
                <div className="page_row right">
                    <button className="solid" onClick={props.createAsset}>
                        Create Asset
                    </button>
                </div>
            </div>
        </Modal>
    );
}
