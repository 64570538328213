import DBModel from './DBModel';
import TierModel from './TierModel';
import AssetModel from './AssetModel';
import ModifierModel from './ModifierModel';

export default class ModifierGroupModel extends DBModel {
    constructor({ name, descriptionKey, tier, assets, modifiers, ...attrs }) {
        super(attrs);
        Object.assign(this, {
            name,
            descriptionKey,
        });
        this.tier = tier && new TierModel(tier);
        this.assets = (assets ?? []).map((asset) => new AssetModel(asset));
        this.modifiers = (modifiers ?? []).map(
            (modifier) => new ModifierModel(modifier)
        );
    }

    static titles = {
        ...super.titles,
        name: 'Name',
        descriptionKey: 'Desc. Key',
        tier: 'Tier',
        assets: 'Assets',
        modifiers: 'Modifiers',
    };

    static primitiveKeys = [...super.primitiveKeys, 'descriptionKey'];
}
