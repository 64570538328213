import styles from './TextBox.module.css';
import LabelWrapper from '../label_wrapper/LabelWrapper';

export default function TextBox(props) {
    const className = `${styles.text_box} ${props.wrap ? styles.wrap : ''} ${
        props.faded ? styles.faded : ''
    }`.trim();

    const textBox = (
        <div id={props.id} className={className}>
            {props.children}
        </div>
    );

    return props.label ? (
        <LabelWrapper label={props.label}>{textBox}</LabelWrapper>
    ) : (
        textBox
    );
}
