import CardModel from './CardModel';

export default class TreeViewModel {
    constructor({ root, nodes = [] }) {
        this.root = root;
        this.nodes = nodes && nodes.map((node) => new TreeViewNodeModel(node));
    }
}

class TreeViewNodeModel {
    constructor({ title = undefined, type, content }) {
        this.title = title;
        this.type = type;
        this.content = this.#setContent(type, content);
    }

    #setContent(type, content) {
        return type === 'card'
            ? new CardModel(content)
            : ['cardMasonry', 'gallery'].includes(type)
            ? {
                  data: content.data,
                  mapper: content.mapper,
                  columnWidth: content.columnWidth,
                  create: content.create,
                  edit: content.edit,
              }
            : content;
    }
}
