import CardModel from '../models/component_models/CardModel';
import StatusEffectModel from '../models/data_models/StatusEffectModel';

export default class StatusEffectUtils {
    static createCardData(dictItem) {
        const statusEffect = new StatusEffectModel(dictItem);
        return new CardModel({
            id: statusEffect.id,
            sections: [
                'descriptionKey',
                'statusEffectType',
                'value',
                'expiration',
            ].map((key) => ({
                type: 'value',
                title: statusEffect.titles[key],
                content: {
                    value: statusEffect[key].toString(),
                },
            })),
        });
    }

    static createCardListData(statusEffects) {
        return statusEffects.map((dictItem) => this.createCardData(dictItem));
    }
}
