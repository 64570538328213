import TierUtils from './TierUtils';
import DataUtils from './DataUtils';
import CardModel from '../models/component_models/CardModel';
import AssetUtils from './AssetUtils';
import AssetModel from '../models/data_models/AssetModel';
import ModifierUtils from '../utils/ModifierUtils';
import TreeViewModel from '../models/component_models/TreeViewModel';
import GalleryImageModel from '../models/component_models/GalleryImageModel';
import ModifierGroupModel from '../models/data_models/ModifierGroupModel';
import {
    GalleryPillLabel,
    GalleryImageBorder,
} from '../components/common/gallery_overlays/GalleryOverlays';

export default class ModifierGroupUtils {
    static sortByTier(groups) {
        return groups.sort((prev, next) => prev.tier?.id - next.tier?.id);
    }

    static createGalleryImageData(dictObj) {
        const obj = new ModifierGroupModel(dictObj);
        const sortedAssets = AssetUtils.sortByAssetType(obj.assets);
        const shownAsset =
            sortedAssets && sortedAssets[0]
                ? new AssetModel(sortedAssets[0])
                : {};
        return new GalleryImageModel({
            id: obj.id,
            alt: shownAsset.assetReference,
            src: shownAsset.imageUrl,
            tooltip: obj.primitiveKeys.map((key) => ({
                title: obj.titles[key],
                value: obj[key],
            })),
            overlays: [
                <GalleryImageBorder color={obj.tier?.colour} />,
                <GalleryPillLabel text={dictObj.count} circular top />,
                <GalleryPillLabel text={obj.name} />,
            ],
        });
    }

    static createGalleryData(dictObjs) {
        const sortedByTier = this.sortByTier(dictObjs);
        return sortedByTier.map((dictObj) =>
            this.createGalleryImageData(dictObj)
        );
    }

    static createCardData(dictObj) {
        const obj = new ModifierGroupModel(dictObj);
        return new CardModel({
            id: obj.id,
            title: obj.name,
            label: dictObj.count,
            borderColor: obj.tier?.colour,
            width: 300,
            sections: [
                {
                    type: 'value',
                    title: obj.titles.id,
                    content: {
                        value: obj.id.toString(),
                    },
                },
                {
                    type: 'gallery',
                    title: obj.titles.assets,
                    content: {
                        data: AssetUtils.createGalleryData(obj.assets),
                        highlight: true,
                    },
                },
                ...['descriptionKey'].map((key) => ({
                    type: 'value',
                    title: obj.titles[key],
                    content: {
                        value: obj[key].toString(),
                    },
                })),
            ],
        });
    }

    static createCardListData(groups) {
        return groups.map((dictItem) => this.createCardData(dictItem));
    }

    static createTreeViewNodes(dictItem) {
        const group = new ModifierGroupModel(dictItem);
        return [
            {
                title: group.titles.tier,
                type: 'card',
                content: TierUtils.createCardData(group.tier),
            },
            {
                title: group.titles.modifiers,
                type: 'cardMasonry',
                content: {
                    data: group.modifiers,
                    mapper: ModifierUtils,
                    columnWidth: 200,
                },
            },
        ];
    }

    static createTreeViewData(dictItem) {
        const cardData = this.createCardData(dictItem);
        return new TreeViewModel({
            root: {
                type: 'card',
                content: cardData,
            },
            nodes: this.createTreeViewNodes(dictItem),
        });
    }

    // TODO: Delete below functions
    static createModifierDTO(
        modifierName,
        modifierDescriptionKey,
        modifierType,
        modifierExpiration,
        modifierValue,
        modifierPrice,
        modifierAssetIds
    ) {
        return {
            name: DataUtils.setString(modifierName),
            descriptionKey: DataUtils.setString(modifierDescriptionKey),
            modifierType: DataUtils.setString(modifierType),
            expiration: DataUtils.setFloat(modifierExpiration),
            value: DataUtils.setFloat(modifierValue),
            price: DataUtils.setFloat(modifierPrice),
            assetIds: modifierAssetIds,
        };
    }

    static createModifierLabelData(modifier) {
        return [
            { title: 'ID', value: modifier.id },
            { title: 'Modifier Name', value: modifier.name },
            { title: 'Desc. Key', value: modifier.descriptionKey },
            { title: 'Modifier Type', value: modifier.modifierType },
            { title: 'Expiration', value: modifier.expiration },
            { title: 'Value', value: modifier.value },
            { title: 'Price', value: modifier.price },
            { title: 'Created At', value: modifier.createdAt },
        ];
    }
}
