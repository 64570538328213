import DBModel from './DBModel';
import DataUtils from '../../utils/DataUtils';
import AssetModel from './AssetModel';

export default class TypeClassModel extends DBModel {
    constructor({ name, descriptionKey, colour, assets, ...attrs }) {
        super(attrs);
        Object.assign(this, {
            name,
            descriptionKey,
            colour,
        });
        this.assets = (assets ?? []).map((asset) => new AssetModel(asset));
    }

    static titles = {
        ...super.titles,
        name: 'Name',
        descriptionKey: 'Desc. Key',
        colour: 'Colour',
        assets: 'Assets',
    };

    static primitiveKeys = [
        ...super.primitiveKeys,
        'name',
        'descriptionKey',
        'colour',
    ];

    static async createDTO({ name, descriptionKey, colour, assetIds }) {
        return {
            name: DataUtils.setString(name),
            descriptionKey: DataUtils.setString(descriptionKey),
            colour: DataUtils.setString(colour),
            assetIds: DataUtils.setList(assetIds, DataUtils.setInt),
        };
    }
}
