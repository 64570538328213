import DBModel from './DBModel';
import DataUtils from '../../utils/DataUtils';

export default class TierModel extends DBModel {
    constructor({ name, colour, ...attr }) {
        super(attr);
        Object.assign(this, { name, colour });
    }

    static titles = {
        ...super.titles,
        name: 'Name',
        colour: 'Colour',
    };

    static primitiveKeys = [...super.primitiveKeys, 'name', 'colour'];

    static async createDTO({ name, colour }) {
        return {
            name: DataUtils.setString(name),
            colour: DataUtils.setString(colour),
        };
    }
}
