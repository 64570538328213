import { useCallback, useEffect, useRef, useState } from 'react';

import { useDropzone } from 'react-dropzone';

import styles from './FileInput.module.css';
import LabelWrapper from '../label_wrapper/LabelWrapper';

export default function FileInput(props) {
    const [file, setFile] = useState(undefined);
    const inputRef = useRef(null);

    useEffect(() => {
        props.onChange(file);
        // eslint-disable-next-line
    }, [file]);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles) {
            setFile(acceptedFiles[0]);
        }
    }, []);

    const convertByteToMB = (byte) => {
        return (byte / 1000000).toFixed(2);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    const fileInputText = isDragActive ? (
        <p className={styles.drag_active}>Drop the file to upload.</p>
    ) : file ? (
        <p>
            ({convertByteToMB(file.size)}MB) {file.name}
        </p>
    ) : (
        <p className={styles.not_selected}>Not uploaded.</p>
    );

    const fileInput = (
        <div className={styles.file_input} {...getRootProps()}>
            <input
                {...getInputProps()}
                id={props.id}
                ref={inputRef}
                type="file"
                onChange={(e) => {
                    setFile(e.target.files[0]);
                }}
            />
            <button
                className={file ? styles.clear : styles.upload}
                onClick={() => {
                    if (file) {
                        inputRef.current.value = '';
                        setFile(undefined);
                    } else {
                        inputRef.current.click();
                    }
                }}
            >
                {file ? 'Clear' : 'Upload File'}
            </button>
            {fileInputText}
        </div>
    );

    return props.label ? (
        <LabelWrapper label={props.label}>{fileInput}</LabelWrapper>
    ) : (
        fileInput
    );
}
