import styles from './LabelWrapper.module.css';

export default function LabelWrapper(props) {
    return (
        <div className={styles.label_wrapper}>
            {props.label && <label>{props.label}</label>}
            {props.children}
        </div>
    );
}
