import { useContext, useEffect, useState } from 'react';

import NotificationContext from '../../../providers/NotificationProvider';
import MasterService from '../../../services/MasterService';
import MasterEditor from './MasterEditor';
import ErrorUtils from '../../../utils/ErrorUtils';
import MasterList from './MasterList';

function Master() {
    // Notification
    const { raiseNotification } = useContext(NotificationContext);

    // Data
    const [masters, setMasters] = useState([]);
    const [selectedMaster, setSelectedMaster] = useState(undefined);
    const [loadingMasters, setLoadingMasters] = useState(true);
    const [loadingSelected, setLoadingSelected] = useState(true);

    // Functions
    const getMasterReferences = async () => {
        setLoadingMasters(true);
        await MasterService.getAllMasterReferences()
            .then((response) => {
                setMasters(response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setLoadingMasters(false);
    };

    const getLatestMaster = async () => {
        setLoadingSelected(true);
        await MasterService.getLatestMaster()
            .then((response) => {
                setSelectedMaster(response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setLoadingSelected(false);
    };

    const selectMasterToView = async (masterId) => {
        setLoadingSelected(true);
        await MasterService.getMaster(masterId)
            .then((response) => {
                setSelectedMaster(response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setLoadingSelected(false);
    };

    // Startup
    useEffect(() => {
        getMasterReferences();
        getLatestMaster();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="page scroll_more">
            <MasterEditor
                getMasters={getMasterReferences}
                selected={selectedMaster}
                loading={loadingSelected}
            />
            <MasterList
                getMasters={getMasterReferences}
                selectMaster={selectMasterToView}
                masters={masters}
                selected={selectedMaster}
                loading={loadingMasters}
            />
        </div>
    );
}

export default Master;
