import Card from '../card/Card';
import Pill from '../pill/Pill';
import Masonry from '../masonry/Masonry';

export default function CardMasonry(props) {
    return (
        <Masonry columnWidth={props.columnWidth}>
            {props.loading
                ? [<Card key={0} loading />, <Card key={1} loading last />]
                : (props.data ?? []).map((item, index) => (
                      <Card
                          key={index}
                          {...item}
                          label={props.setCount ? undefined : item.label}
                          onClick={
                              props.onClick
                                  ? () => props.onClick(item.id)
                                  : undefined
                          }
                          toolbar={
                              <>
                                  {props.setCount && (
                                      <div
                                          style={{
                                              position: 'absolute',
                                              height: 'fit-content',
                                              width: 'fit-content',
                                              maxWidth: 'calc(100% - 5px * 2)',
                                              margin: '5px',
                                              display: 'flex',
                                              gap: '5px',
                                              right: 0,
                                              top: 0,
                                          }}
                                      >
                                          <Pill
                                              text={'-'}
                                              color={'var(--accent-color)'}
                                              circular
                                              onClick={() => {
                                                  props.setCount(
                                                      item.id,
                                                      item.label - 1
                                                  );
                                              }}
                                          />
                                          <Pill text={item.label} circular />
                                          <Pill
                                              text={'+'}
                                              color={'var(--primary-color)'}
                                              circular
                                              onClick={() => {
                                                  props.setCount(
                                                      item.id,
                                                      item.label + 1
                                                  );
                                              }}
                                          />
                                      </div>
                                  )}
                                  {item.toolbar}
                              </>
                          }
                      />
                  ))}
        </Masonry>
    );
}
