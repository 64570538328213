import DataModel from './DataModel';

export default class DBModel extends DataModel {
    id;
    createdAt;

    constructor({ id, createdAt, ...attrs }) {
        super(attrs);
        this.id = id;
        this.createdAt = createdAt;
    }

    static titles = { ...super.titles, id: 'ID', createdAt: 'Created At' };

    static primitiveKeys = [...super.primitiveKeys, 'id', 'createdAt'];
}
