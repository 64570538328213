import DBModel from './DBModel';
import DataUtils from '../../utils/DataUtils';

export default class StatusEffectModel extends DBModel {
    constructor({
        descriptionKey,
        statusEffectType,
        value,
        expiration,
        ...attrs
    }) {
        super(attrs);
        Object.assign(this, {
            descriptionKey,
            statusEffectType,
            value,
            expiration,
        });
    }

    static titles = {
        ...super.titles,
        descriptionKey: 'Desc. Key',
        statusEffectType: 'Type',
        value: 'Value',
        expiration: 'Expiration',
    };

    static primitiveKeys = [
        ...super.primitiveKeys,
        'descriptionKey',
        'type',
        'value',
        'expiration',
    ];

    static async createDTO({
        descriptionKey,
        statusEffectType,
        value,
        expiration,
    }) {
        return {
            descriptionKey: DataUtils.setString(descriptionKey),
            statusEffectType: DataUtils.setString(statusEffectType),
            value: DataUtils.setString(value),
            expiration: DataUtils.setFloat(expiration),
        };
    }
}
