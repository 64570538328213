import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import TreeView from '../../common/tree_view/TreeView';
import LevelUtils from '../../../utils/LevelUtils';
import StateContext from '../../../providers/StateProvider';
import WaveFormModal from './wave_form_modal/WaveFormModal';
import StateConstants from '../../../constants/StateConstants';

export default function LevelInfo(props) {
    // Navigation
    const navigate = useNavigate();
    const params = useParams();

    // States
    const { state } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const [waveFormMode, setWaveFormMode] = useState(undefined);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        setData(
            params.levelId
                ? state[commonStates.LEVELS].find(
                      (item) => item.id === params.levelId
                  )
                : undefined
        );
        // eslint-disable-next-line
    }, [params.levelId, state[commonStates.LOADING_LEVELS]]);

    useEffect(() => {
        setWaveFormMode(
            props.mode === 'createWave'
                ? 'create'
                : props.mode === 'editWave'
                ? 'edit'
                : undefined
        );
        // eslint-disable-next-line
    }, [props.mode]);

    const treeViewData = data
        ? LevelUtils.createTreeViewData(data, navigate)
        : {};

    return (
        <>
            <div
                className="flex_row full_width padding border_bottom"
                style={{
                    minHeight: 'var(--topbar-height)',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <h2 className="align_left">
                    <span
                        className="clickable"
                        onClick={() => {
                            navigate('/levels');
                        }}
                    >
                        Levels
                    </span>
                    {` > Info > ${params.levelId}`}
                </h2>
            </div>
            <div className="flex_column full_width padding">
                {data ? <TreeView {...treeViewData} /> : <p>No Content</p>}
            </div>
            <WaveFormModal
                mode={waveFormMode}
                visible={Boolean(waveFormMode)}
                setVisible={setWaveFormMode}
                onClose={() => {
                    navigate(`/levels/${params.levelId}`);
                }}
                getLevels={props.getLevels}
            />
        </>
    );
}
