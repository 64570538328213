import CardModel from '../models/component_models/CardModel';
import TreeViewModel from '../models/component_models/TreeViewModel';
import TypeClassUtils from './TypeClassUtils';
import { GalleryPillLabel } from '../components/common/gallery_overlays/GalleryOverlays';
import { DamageMultiplierRuleModel } from '../models/data_models/LevelModel';

export default class DamageMultiplierRuleUtils {
    static createCardData(dictObj) {
        const obj = new DamageMultiplierRuleModel(dictObj);
        return new CardModel({
            id: dictObj.id,
            title: `Rule ${dictObj.id}`,
            width: 150,
            sections: [
                {
                    type: 'value',
                    title: obj.titles.multiplier,
                    content: {
                        value: obj.multiplier.toString(),
                    },
                },
            ],
        });
    }

    static createSmallCardData(dictObj) {
        const obj = new DamageMultiplierRuleModel(dictObj);
        return new CardModel({
            id: dictObj.id,
            title: `Rule ${dictObj.id}`,
            width: 150,
            sections: [
                {
                    type: 'gallery',
                    content: {
                        data: ['from', 'to'].map((key) => ({
                            ...TypeClassUtils.createGalleryImageData(obj[key]),
                            overlays: [
                                <GalleryPillLabel text={obj.titles[key]} />,
                            ],
                        })),
                    },
                },
                {
                    type: 'value',
                    title: obj.titles.multiplier,
                    content: {
                        value: obj.multiplier.toString(),
                    },
                },
            ],
        });
    }

    static createCardListData(dictObjs) {
        return dictObjs.map((dictObj) => this.createSmallCardData(dictObj));
    }

    static createTreeViewNodes(dictObj) {
        const obj = new DamageMultiplierRuleModel(dictObj);
        return [
            {
                title: obj.titles.from,
                type: 'card',
                content: TypeClassUtils.createCardData(obj.from),
            },
            {
                title: obj.titles.to,
                type: 'card',
                content: TypeClassUtils.createCardData(obj.to),
            },
        ];
    }

    static createTreeViewData(dictObj) {
        return new TreeViewModel({
            root: {
                type: 'card',
                content: this.createCardData(dictObj),
            },
            nodes: this.createTreeViewNodes(dictObj),
        });
    }
}
