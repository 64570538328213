import DataUtils from './DataUtils';
import CardModel from '../models/component_models/CardModel';
import AssetUtils from './AssetUtils';
import ArmorUtils from './ArmorUtils';
import SkillUtils from './SkillUtils';
import AssetModel from '../models/data_models/AssetModel';
import EntityModel from '../models/data_models/EntityModel';
import TreeViewModel from '../models/component_models/TreeViewModel';
import BaseAttackUtils from './BaseAttackUtils';
import GalleryImageModel from '../models/component_models/GalleryImageModel';
import EnumerationConstants from '../constants/EnumerationConstants';
import { GalleryPillLabel } from '../components/common/gallery_overlays/GalleryOverlays';

class EntityUtils {
    static filterByValue(dictObjs, searchedValue) {
        const searchKeys = ['id', 'name', 'descriptionKey', 'colour'];
        const regex = new RegExp(searchedValue.toLowerCase());

        return dictObjs.filter((dictObj) => {
            const searchValues = searchKeys.map((key) => dictObj[key]);

            const isAnyValueMatch = searchValues
                .map((value) => regex.test(value.toString().toLowerCase()))
                .some(Boolean);

            return isAnyValueMatch;
        });
    }

    static createGalleryImageData(dictObj) {
        const obj = new EntityModel(dictObj);
        const sortedAssets = AssetUtils.sortByAssetType(obj.assets);
        const shownAsset = sortedAssets ? new AssetModel(sortedAssets[0]) : {};
        return new GalleryImageModel({
            id: obj.id,
            alt: shownAsset.assetReference,
            src: shownAsset.imageUrl,
            tooltip: obj.primitiveKeys.map((key) => ({
                title: obj.titles[key],
                value: obj[key],
            })),
            overlays: [
                ...(dictObj.count !== undefined
                    ? [<GalleryPillLabel text={dictObj.count} circular top />]
                    : []),
                <GalleryPillLabel
                    text={obj.name}
                    color={
                        obj.armor?.type?.colour ||
                        obj.baseAttack?.damageType?.colour
                    }
                />,
            ],
        });
    }

    static createGalleryData(entities) {
        return entities.map((entity) => this.createGalleryImageData(entity));
    }

    static createCardData(dictObj) {
        const obj = new EntityModel(dictObj);
        return new CardModel({
            id: obj.id,
            title: obj.name,
            color:
                obj.armor?.type?.colour || obj.baseAttack?.damageType?.colour,
            label: dictObj.count,
            width: 300,
            sections: [
                {
                    type: 'gallery',
                    title: obj.titles.assets,
                    content: {
                        data: AssetUtils.createGalleryData(obj.assets),
                        highlight: true,
                    },
                },
                ...[
                    'descriptionKey',
                    'healthPoints',
                    'baseHPRegen',
                    'sizeScale',
                    'colour',
                    'movementSpeed',
                    'price',
                    'teamId',
                ].map((key) => ({
                    type: 'value',
                    title: obj.titles[key],
                    content: {
                        value: obj[key].toString(),
                    },
                })),
            ],
        });
    }

    static createCardListData(entities) {
        return entities.map((entityDict) => this.createCardData(entityDict));
    }

    static createTreeViewNodes(dictObj) {
        const obj = new EntityModel(dictObj);
        return [
            {
                title: obj.titles.armor,
                type: 'treeView',
                content: ArmorUtils.createTreeViewData(obj.armor),
            },
            {
                title: obj.titles.baseAttack,
                type: 'treeView',
                content: BaseAttackUtils.createTreeViewData(obj.baseAttack),
            },
            {
                title: obj.titles.skills,
                type: 'cardMasonry',
                content: {
                    data: obj.skills,
                    mapper: SkillUtils,
                },
            },
        ];
    }

    static createTreeViewData(entityDict) {
        const entityCardData = this.createCardData(entityDict);
        return new TreeViewModel({
            root: { type: 'card', content: entityCardData },
            nodes: this.createTreeViewNodes(entityDict),
        });
    }

    // TODO: Delete below functions
    static createEntityDTO(
        entityName,
        entityDescriptionKey,
        entityPrice,
        entityTeamId,
        entitySizeScale,
        entityHealthPoints,
        entityMovementSpeed,
        entityBaseHPRegen,
        entityArmour,
        entityArmourTypeId,
        entityBAType,
        entityBARange,
        entityBASpeed,
        entityBAProjectileSpeed,
        entityBADamage,
        entityBADamageTypeId,
        entitySkillIds,
        entityModifierIds,
        entityAssetIds
    ) {
        return {
            name: DataUtils.setString(entityName),
            descriptionKey: DataUtils.setString(entityDescriptionKey),
            price: DataUtils.setFloat(entityPrice),
            teamId: DataUtils.setInt(entityTeamId),
            sizeScale: DataUtils.setFloat(entitySizeScale),
            healthPoints: DataUtils.setFloat(entityHealthPoints),
            movementSpeed: DataUtils.setFloat(entityMovementSpeed),
            baseHPRegen: DataUtils.setFloat(entityBaseHPRegen),
            armour: DataUtils.setFloat(entityArmour),
            armourTypeId: DataUtils.setInt(entityArmourTypeId),
            baseAttackType: DataUtils.setString(entityBAType),
            baseAttackRange: DataUtils.setFloat(entityBARange),
            baseAttackSpeed: DataUtils.setFloat(entityBASpeed),
            baseAttackProjectileSpeed: DataUtils.setFloat(
                entityBAProjectileSpeed
            ),
            baseAttackDamage: DataUtils.setFloat(entityBADamage),
            baseAttackDamageTypeId: DataUtils.setInt(entityBADamageTypeId),
            skillIds: entitySkillIds,
            modifierIds: entityModifierIds,
            assetIds: entityAssetIds,
        };
    }

    static createEntityLabelData(entity) {
        return [
            { title: 'ID', value: entity.id },
            { title: 'Entity Name', value: entity.name },
            { title: 'Desc. Key', value: entity.descriptionKey },
            { title: 'Health Points', value: entity.healthPoints },
            { title: 'Armour', value: entity.armour },
            { title: 'Movement Speed', value: entity.movementSpeed },
            { title: 'Price', value: entity.price },
            { title: 'Team ID', value: entity.teamId },
            { title: 'Created At', value: entity.createdAt },
        ];
    }

    static createArmourLabelData(entity) {
        return [
            { title: 'Type Class ID', value: entity.armourType.id },
            { title: 'Type Class Name', value: entity.armourType.name },
            { title: 'Armour Value', value: entity.armour },
        ];
    }

    static createBaseAttackLabelData(entity) {
        return [
            {
                title: 'Type',
                value: EnumerationConstants.AttackTypes[entity.baseAttackType],
            },
            { title: 'Damage Type ID', value: entity.baseAttackDamageType.id },
            { title: 'Damage Type', value: entity.baseAttackDamageType.name },
            { title: 'Damage', value: entity.baseAttackDamage },
            { title: 'Range', value: entity.baseAttackRange },
            { title: 'Speed', value: entity.baseAttackSpeed },
            {
                title: 'Projectile Speed',
                value: entity.baseAttackProjectileSpeed,
            },
        ];
    }
}

export default EntityUtils;
