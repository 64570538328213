import DBModel from './DBModel';
import DataModel from './DataModel';
import DataUtils from '../../utils/DataUtils';
import EntityModel from './EntityModel';

export default class WaveModel extends DBModel {
    constructor({
        name,
        descriptionKey,
        priority,
        duration,
        modifierIds,
        modifierGroupIds,
        waveEntities,
        ...attrs
    }) {
        super(attrs);
        Object.assign(this, {
            name,
            descriptionKey,
            priority,
            duration,
            modifierIds,
            modifierGroupIds,
        });
        this.waveEntities = (waveEntities ?? []).map(
            (waveEntity) => new WaveEntityModel(waveEntity)
        );
    }

    static titles = {
        ...super.titles,
        name: 'Name',
        descriptionKey: 'Desc. Key',
        priority: 'Priority',
        duration: 'Duration',
        waveEntities: 'Wave Entities',
    };

    static createDTO({
        name,
        descriptionKey,
        priority,
        duration,
        modifierIds,
        modifierGroupIds,
        waveEntities,
    }) {
        return {
            name: DataUtils.setString(name),
            descriptionKey: DataUtils.setString(descriptionKey),
            priority: DataUtils.setInt(priority),
            duration: DataUtils.setFloat(duration),
            modifierIds: DataUtils.setList(modifierIds, DataUtils.setInt),
            modifierGroupIds: DataUtils.setList(
                modifierGroupIds,
                DataUtils.setInt
            ),
            waveEntities: DataUtils.setList(
                waveEntities,
                WaveEntityModel.createDTO
            ),
        };
    }
}

class WaveEntityModel extends DataModel {
    constructor({ entity, count, ...attrs }) {
        super(attrs);
        this.count = count;
        this.entity = new EntityModel(entity);
    }

    static titles = {
        ...super.titles,
        entity: 'Entity',
        count: 'Count',
    };

    static createDTO({ entityId, count }) {
        return {
            entityId: DataUtils.setInt(entityId),
            count: DataUtils.setInt(count),
        };
    }
}
