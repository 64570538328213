import { useEffect, useState } from 'react';

import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';

import styles from './TextInput.module.css';
import LabelWrapper from '../label_wrapper/LabelWrapper';

export default function TextInput(props) {
    const [showPassword, setShowPassword] = useState(false);
    const [currentValue, setCurrentValue] = useState('');

    useEffect(() => {
        setCurrentValue(props.value ? props.value : '');
        props.onChange(props.value ? props.value : '');
        // eslint-disable-next-line
    }, [props.value]);

    const isInputValid = (type, input) => {
        if (type === 'int') {
            const inputPattern = /^[0-9-]*$/i;
            return inputPattern.test(input);
        } else if (type === 'float') {
            const inputPattern = /^[0-9.-]*$/i;
            return inputPattern.test(input);
        } else {
            return true;
        }
    };

    const handleChange = (e) => {
        if (isInputValid(props.type, e.target.value)) {
            setCurrentValue(e.target.value);
            props.onChange(e.target.value);
        } else {
            e.target.value = currentValue;
        }
    };

    const handleKeyDown = (e) => {
        if (props.onEnterFocusId && e.key === 'Enter') {
            e.preventDefault();
            const element = document.getElementById(props.onEnterFocusId);
            if (element) {
                element.focus();
            }
        }
    };

    const textInput = (
        <div className={styles.text_input}>
            <input
                id={props.id}
                type={props.password && !showPassword ? 'password' : undefined}
                placeholder={props.placeholder}
                value={props.value}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
            {props.password &&
                (showPassword ? (
                    <RiEyeLine
                        size={25}
                        className={styles.password_icon}
                        onClick={() => setShowPassword(false)}
                    />
                ) : (
                    <RiEyeOffLine
                        size={25}
                        className={styles.password_icon}
                        onClick={() => setShowPassword(true)}
                    />
                ))}
            {props.suffix && (
                <div className={styles.suffix}>{props.suffix}</div>
            )}
        </div>
    );

    return props.label ? (
        <LabelWrapper label={props.label}>{textInput}</LabelWrapper>
    ) : (
        textInput
    );
}
