import DBModel from './DBModel';
import TierModel from './TierModel';
import DataUtils from '../../utils/DataUtils';
import AssetModel from './AssetModel';
import ModifierModel from './ModifierModel';
import TypeClassModel from './TypeClassModel';
import StatusEffectModel from './StatusEffectModel';

export default class SkillModel extends DBModel {
    constructor({
        name,
        descriptionKey,
        damage,
        attackSpeed,
        attackType,
        projectileSpeed,
        projectileAngle,
        range,
        price,
        colour,
        damageType,
        tier,
        assets,
        modifiers,
        statusEffects,
        ...attrs
    }) {
        super(attrs);
        Object.assign(this, {
            name,
            descriptionKey,
            damage,
            attackSpeed,
            attackType,
            projectileSpeed,
            projectileAngle,
            range,
            price,
            colour,
        });
        this.damageType = damageType && new TypeClassModel(damageType);
        this.tier = tier && new TierModel(tier);
        this.assets = (assets ?? []).map((asset) => new AssetModel(asset));
        this.modifiers = (modifiers ?? []).map(
            (modifier) => new ModifierModel(modifier)
        );
        this.statusEffects = (statusEffects ?? []).map(
            (statusEffect) => new StatusEffectModel(statusEffect)
        );
    }

    static titles = {
        ...super.titles,
        name: 'Name',
        descriptionKey: 'Desc. Key',
        damage: 'Damage',
        attackSpeed: 'Attack Speed',
        attackType: 'Attack Type',
        projectileSpeed: 'Projectile Speed',
        projectileAngle: 'Projectile Angle',
        range: 'Range',
        price: 'Price',
        colour: 'Colour',
        damageType: 'Damage Type',
        tier: 'Tier',
        assets: 'Assets',
        modifiers: 'Modifiers',
        statusEffects: 'Status Effects',
    };

    static primitiveKeys = [
        ...super.primitiveKeys,
        'name',
        'descriptionKey',
        'damage',
        'attackSpeed',
        'attackType',
        'projectileSpeed',
        'projectileAngle',
        'range',
        'price',
        'colour',
    ];

    static createDTO({
        name,
        descriptionKey,
        damage,
        attackSpeed,
        attackType,
        projectileSpeed,
        projectileAngle,
        range,
        price,
        colour,
        damageTypeId,
        tierId,
        assetIds,
        modifierIds,
        statusEffectIds,
    }) {
        return {
            name: DataUtils.setString(name),
            descriptionKey: DataUtils.setString(descriptionKey),
            damage: DataUtils.setFloat(damage),
            attackSpeed: DataUtils.setFloat(attackSpeed),
            attackType: DataUtils.setString(attackType),
            projectileSpeed: DataUtils.setFloat(projectileSpeed),
            projectileAngle: DataUtils.setFloat(projectileAngle),
            range: DataUtils.setFloat(range),
            price: DataUtils.setFloat(price),
            colour: DataUtils.setString(colour),
            damageTypeId: DataUtils.setInt(damageTypeId),
            tierId: DataUtils.setInt(tierId),
            assetIds: DataUtils.setList(assetIds, DataUtils.setInt),
            modifierIds: DataUtils.setList(modifierIds, DataUtils.setInt),
            statusEffectIds: DataUtils.setList(
                statusEffectIds,
                DataUtils.setInt
            ),
        };
    }
}
