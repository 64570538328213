import { useContext, useState } from 'react';

import Gallery from '../../common/gallery/Gallery';
import Dropdown from '../../common/dropdown/Dropdown';
import EnumUtils from '../../../utils/EnumUtils';
import TextInput from '../../common/text_input/TextInput';
import AssetUtils from '../../../utils/AssetUtils';
import StateContext from '../../../providers/StateProvider';
import StateConstants from '../../../constants/StateConstants';

function AssetList(props) {
    const { state } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const enumStates = StateConstants.EnumStates;
    const [searchKey, setSearchKey] = useState('');
    const [assetType, setAssetType] = useState('');

    return (
        <div className="flex_column full_width padding">
            <div className="flex_row full_width" style={{ flexWrap: 'wrap' }}>
                <div style={{ minWidth: '200px', maxWidth: '200px' }}>
                    <Dropdown
                        id="assetFilterType"
                        label="Asset Type Filter"
                        onChange={setAssetType}
                        defaultValue=""
                    >
                        <option key="" value="">
                            All
                        </option>
                        {EnumUtils.createTitleMapping(
                            state[enumStates.ASSET_TYPES]
                        ).map(({ title, value }, index) => (
                            <option key={index} value={value}>
                                {title}
                            </option>
                        ))}
                    </Dropdown>
                </div>
                <div
                    style={{
                        flex: 1,
                        minWidth: '200px',
                        maxWidth: '400px',
                    }}
                >
                    <TextInput label="Search Filter" onChange={setSearchKey} />
                </div>
            </div>
            <Gallery
                loading={state[commonStates.LOADING_ASSETS]}
                data={AssetUtils.createGalleryData(state[commonStates.ASSETS], {
                    searchKey: searchKey,
                    assetType: assetType,
                })}
                onClick={props.select}
            />
        </div>
    );
}

export default AssetList;
