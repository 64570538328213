import StringUtils from './StringUtils';

export default class EnumUtils {
    static createTitleMapping(enumStrings) {
        return enumStrings.map((enumString) => ({
            title: StringUtils.camelCaseToTitle(enumString),
            value: enumString,
        }));
    }
}
