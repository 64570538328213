import DBModel from './DBModel';
import DataUtils from '../../utils/DataUtils';
import TypeClassModel from './TypeClassModel';

export default class ModifierModel extends DBModel {
    constructor({
        name,
        descriptionKey,
        modifierType,
        expiration,
        value,
        repeats,
        attackType,
        chance,
        range,
        stacking,
        ifEffectedBy,
        typeClass,
        ...attrs
    }) {
        super(attrs);
        Object.assign(this, {
            name,
            descriptionKey,
            modifierType,
            expiration,
            value,
            repeats,
            attackType,
            chance,
            range,
            stacking,
            ifEffectedBy,
        });
        this.typeClass = typeClass && new TypeClassModel(typeClass);
    }

    static titles = {
        ...super.titles,
        name: 'Name',
        descriptionKey: 'Desc. Key',
        modifierType: 'Modifier Type',
        expiration: 'Expiration',
        value: 'Value',
        repeats: 'Repeats',
        attackType: 'Attack Type',
        chance: 'Chance',
        range: 'Range',
        stacking: 'Stacking',
        ifEffectedBy: 'If Effected By',
        typeClass: 'Type Class',
    };

    static primitiveKeys = [
        ...super.primitiveKeys,
        'name',
        'descriptionKey',
        'modifierType',
        'expiration',
        'value',
        'repeats',
        'attackType',
        'chance',
        'range',
        'stacking',
        'ifEffectedBy',
    ];

    static createDTO({
        name,
        descriptionKey,
        modifierType,
        expiration,
        value,
        repeats,
        attackType,
        chance,
        range,
        stacking,
        ifEffectedBy,
        multiplyPerModifierId,
        multiplyPerSkillId,
        typeClassId,
        assetIds,
    }) {
        return {
            name: DataUtils.setString(name),
            descriptionKey: DataUtils.setString(descriptionKey),
            modifierType: DataUtils.setString(modifierType),
            expiration: DataUtils.setFloat(expiration),
            value: DataUtils.setFloat(value),
            repeats: DataUtils.setFloat(repeats),
            attackType: DataUtils.setString(attackType),
            chance: DataUtils.setFloat(chance),
            range: DataUtils.setFloat(range),
            stacking: DataUtils.setBool(stacking),
            ifEffectedBy: DataUtils.setString(ifEffectedBy),
            multiplyPerModifierId: DataUtils.setInt(multiplyPerModifierId),
            multiplyPerSkillId: DataUtils.setInt(multiplyPerSkillId),
            typeClassId: DataUtils.setInt(typeClassId),
            assetIds: DataUtils.setList(assetIds, DataUtils.setInt),
        };
    }
}
