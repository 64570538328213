import { useEffect, useRef, useState } from 'react';

import styles from './Tooltip.module.css';

function Tooltip(props) {
    const tooltipRef = useRef();
    const [appeared, setAppeared] = useState(false);

    useEffect(() => {
        const handleTooltipTransform = (e) => {
            if (tooltipRef.current) {
                if (e.clientX > window.innerWidth / 2) {
                    tooltipRef.current.style.left = 'unset';
                    tooltipRef.current.style.right = `${
                        window.innerWidth - e.clientX
                    }px`;
                } else {
                    tooltipRef.current.style.right = 'unset';
                    tooltipRef.current.style.left = `${e.clientX}px`;
                }
                if (e.clientY > window.innerHeight / 2) {
                    tooltipRef.current.style.top = 'unset';
                    tooltipRef.current.style.bottom = `${
                        window.innerHeight - e.clientY
                    }px`;
                } else {
                    tooltipRef.current.style.bottom = 'unset';
                    tooltipRef.current.style.top = `${e.clientY}px`;
                }
                if (!appeared) {
                    setAppeared(true);
                }
            }
        };
        if (props.visible) {
            document.addEventListener('mousemove', handleTooltipTransform);
        } else {
            document.removeEventListener('mousemove', handleTooltipTransform);
        }
        // eslint-disable-next-line
    }, [props.visible]);

    const tooltipClassName = `${styles.tooltip} ${
        props.visible && appeared ? '' : styles.hidden
    }`;

    return (
        <div ref={tooltipRef} className={tooltipClassName}>
            {props.data &&
                props.data.map((item, index) => (
                    <p key={index}>
                        <b>{item.title}:</b> {item.value}
                    </p>
                ))}
        </div>
    );
}

export default Tooltip;
