import { useEffect, useRef, useState } from 'react';

import { FaXmark } from 'react-icons/fa6';

import styles from './Modal.module.css';

function Modal(props) {
    const modalRef = useRef();
    const [dragActive, setDragActive] = useState(false);

    useEffect(() => {
        let mouseStartCoord, modalStartCoord;

        const handleModalTransform = (e) => {
            if (modalRef && modalRef.current) {
                const setCoordValue = (newValue, { min = 0, max = 0 }) => {
                    if (newValue < min) {
                        return min;
                    } else if (newValue > max) {
                        return max;
                    } else {
                        return newValue;
                    }
                };
                if (!mouseStartCoord) {
                    mouseStartCoord = { x: e.clientX, y: e.clientY };
                }
                if (!modalStartCoord) {
                    modalStartCoord = {
                        x: parseInt(modalRef.current.style.left),
                        y: parseInt(modalRef.current.style.top),
                    };
                }
                const mouseDelta = {
                    x: e.clientX - mouseStartCoord.x,
                    y: e.clientY - mouseStartCoord.y,
                };
                const newModalCoord = {
                    x: setCoordValue(modalStartCoord.x + mouseDelta.x, {
                        min: 10,
                        max:
                            window.innerWidth -
                            modalRef.current.offsetWidth -
                            10,
                    }),
                    y: setCoordValue(modalStartCoord.y + mouseDelta.y, {
                        min: 10,
                        max:
                            window.innerHeight -
                            modalRef.current.offsetHeight -
                            10,
                    }),
                };
                modalRef.current.style.left = `${newModalCoord.x}px`;
                modalRef.current.style.top = `${newModalCoord.y}px`;
            }
        };

        const stopModalTransform = () => {
            setDragActive(false);
            document.removeEventListener('mousemove', handleModalTransform);
            document.removeEventListener('mouseup', stopModalTransform);
            mouseStartCoord = undefined;
            modalStartCoord = undefined;
        };

        if (props.visible && dragActive) {
            document.addEventListener('mousemove', handleModalTransform);
            document.addEventListener('mouseup', stopModalTransform);
        }
        // eslint-disable-next-line
    }, [dragActive]);

    const modalBackgroundClassName = `${styles.modal_background} ${
        props.visible ? '' : styles.hidden
    }`.trim();

    const modalClassName = `${styles.modal} ${
        props.draggable ? styles.draggable : ''
    } ${props.fullScreen ? styles.full_screen : ''} ${
        props.visible ? '' : styles.hidden
    }`.trim();

    const modal = (
        <div
            ref={props.draggable ? modalRef : undefined}
            className={modalClassName}
            style={props.draggable ? { top: '10px', left: '10px' } : undefined}
        >
            {(!props.noCloseIcon || props.title) && (
                <div
                    className={styles.header}
                    onMouseDown={() => setDragActive(true)}
                >
                    {!props.noCloseIcon && (
                        <button
                            onClick={() => {
                                props.setVisible(false);
                                props.onClose && props.onClose();
                            }}
                        >
                            <FaXmark />
                        </button>
                    )}
                    {props.title && <h5>{props.title}</h5>}
                </div>
            )}
            <div className={styles.content}>{props.children}</div>
        </div>
    );

    return props.draggable ? (
        modal
    ) : (
        <div className={modalBackgroundClassName}>{modal}</div>
    );
}

export default Modal;
