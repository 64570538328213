import { useState } from 'react';

import { FaCaretDown, FaCaretUp, FaPen, FaXmark } from 'react-icons/fa6';

import Card from '../card/Card';
import styles from './TreeView.module.css';
import Gallery from '../gallery/Gallery';
import CardMasonry from '../card_masonry/CardMasonry';

export default function TreeView(props) {
    return (
        <div className={styles.tree_view}>
            {props.root &&
                (props.root.type === 'card' ? (
                    <Card {...props.root.content} />
                ) : (
                    props.root
                ))}
            {(props.nodes ?? []).map((node, index) => {
                const last = index + 1 === props.nodes.length;
                return (
                    <TreeViewNode
                        key={index}
                        title={node.title}
                        type={node.type}
                        content={node.content}
                        last={last}
                    />
                );
            })}
        </div>
    );
}

function TreeViewNode(props) {
    const [expanded, setExpanded] = useState(false);

    const handleExpand = () => {
        setExpanded((prevState) => !prevState);
    };

    const verticalClassName = `${styles.vertical} ${
        props.last ? styles.last : ''
    }`.trim();

    const headerClassName = `${styles.header} ${
        props.content ? '' : styles.no_content
    }`.trim();

    const contentClassName = `${styles.content} ${
        !props.title || expanded ? styles.visible : ''
    }`.trim();

    return (
        <div className={styles.tree_view_node}>
            <div className={styles.lines}>
                <div className={verticalClassName}></div>
                <div className={styles.horizontal}>
                    <div className={styles.dot}></div>
                </div>
            </div>
            <div className={styles.node}>
                {props.title && (
                    <div
                        className={headerClassName}
                        onClick={props.content && handleExpand}
                    >
                        {props.content &&
                            (expanded ? (
                                <FaCaretUp size={18} />
                            ) : (
                                <FaCaretDown size={18} />
                            ))}
                        <p className={styles.title}>
                            {props.title}
                            {props.content &&
                                ['cardMasonry', 'gallery'].includes(
                                    props.type
                                ) && (
                                    <span>{` (${
                                        (props.content.data ?? []).length
                                    })`}</span>
                                )}
                            {!expanded && (
                                <span>
                                    {' '}
                                    {props.content ? '...' : '(No Content)'}
                                </span>
                            )}
                        </p>
                    </div>
                )}
                {(!props.title || expanded) && (
                    <div className={contentClassName}>
                        {props.type === 'treeView' ? (
                            <TreeView {...props.content} />
                        ) : props.type === 'card' ? (
                            <Card {...props.content} />
                        ) : props.type === 'cardMasonry' ? (
                            <NodeList type="cardMasonry" {...props.content} />
                        ) : props.type === 'gallery' ? (
                            <NodeList type="gallery" {...props.content} />
                        ) : undefined}
                    </div>
                )}
            </div>
        </div>
    );
}

function NodeList(props) {
    const [expandedIds, setExpandedIds] = useState([]);

    const handleClick = (id) => {
        setExpandedIds((prevState) => {
            if (prevState.includes(id)) {
                return prevState.filter((prevId) => prevId !== id);
            } else {
                return [...prevState, id];
            }
        });
    };

    return (
        <>
            {props.create && (
                <button className="solid" onClick={props.create}>
                    Create New
                </button>
            )}
            {props.type === 'cardMasonry' ? (
                <CardMasonry
                    data={props.mapper.createCardListData(
                        (props.data ?? []).filter(
                            (item) => !expandedIds.includes(item.id)
                        ) ?? []
                    )}
                    onClick={handleClick}
                    columnWidth={props.columnWidth}
                />
            ) : props.type === 'gallery' ? (
                <Gallery
                    data={props.mapper.createGalleryData(
                        (props.data ?? []).filter(
                            (item) => !expandedIds.includes(item.id)
                        ) ?? []
                    )}
                    onClick={handleClick}
                    columnWidth={props.columnWidth ?? 70}
                />
            ) : undefined}
            {(props.data ?? [])
                .filter((item) => expandedIds.includes(item.id))
                .map((item, index) => {
                    return (
                        <TreeView
                            key={index}
                            root={{
                                type: 'card',
                                content: {
                                    ...props.mapper.createCardData(item),
                                    toolbar: (
                                        <>
                                            {props.edit && (
                                                <button
                                                    onClick={() =>
                                                        props.edit(item.id)
                                                    }
                                                >
                                                    <FaPen />
                                                </button>
                                            )}
                                            <button
                                                onClick={() =>
                                                    handleClick(item.id)
                                                }
                                            >
                                                <FaXmark />
                                            </button>
                                        </>
                                    ),
                                },
                            }}
                            nodes={props.mapper.createTreeViewNodes(item)}
                        />
                    );
                })}
        </>
    );
}
