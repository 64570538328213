import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Login.module.css';
import TextInput from '../../common/text_input/TextInput';
import AuthService from '../../../services/AuthService';
import UserService from '../../../services/UserService';
import LocalStorage from '../../../constants/LocalStorageConstants';
import NotificationContext from '../../../providers/NotificationProvider';

export default function Login() {
    const navigate = useNavigate();
    const { raiseNotification, removeNotification } =
        useContext(NotificationContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const login = async () => {
        const notificationId = raiseNotification('Logging in...', {
            permanent: true,
        });
        try {
            await AuthService.getAccessToken(email, password);
            const response = await UserService.getUserById(
                localStorage[LocalStorage.USER_ID]
            );
            localStorage[LocalStorage.USER_NAME] = response.data['name'];
            removeNotification(notificationId);
            raiseNotification('Login successful.', {
                type: 'success',
            });
            navigate('/');
        } catch (error) {
            removeNotification(notificationId);
            raiseNotification('Failed to log in.', {
                type: 'error',
            });
        }
    };

    return (
        <div className="page cover_window">
            <div className={styles.login}>
                <div className={styles.banner}>
                    <h2>This is</h2>
                    <h1>Pocket Factory AB</h1>
                </div>
                <div className={styles.form}>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            login();
                        }}
                    >
                        <h2>Welcome</h2>
                        <TextInput
                            id="email"
                            placeholder="Email"
                            onEnterFocusId="password"
                            onChange={setEmail}
                        />
                        <TextInput
                            id="password"
                            placeholder="Password"
                            onChange={setPassword}
                            password
                        />
                        <button className="solid" submit="true">
                            Log in
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
