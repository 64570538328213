export default class DataModel {
    static titles = {};
    get titles() {
        return this.constructor.titles;
    }

    static primitiveKeys = [];
    get primitiveKeys() {
        return this.constructor.primitiveKeys;
    }

    static async createDTO() {}

    getTitleValueMapping() {
        return this.constructor.primitiveKeys.map((key) => ({
            title: this.constructor.titles[key],
            value: this[key],
        }));
    }
}
