export default class GalleryImageModel {
    constructor({ id, alt, src, tooltip = undefined, overlays = undefined }) {
        Object.assign(this, {
            id,
            alt,
            src,
            tooltip,
            overlays,
        });
    }
}
