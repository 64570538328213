import { useContext, useState } from 'react';

import TextInput from '../../common/text_input/TextInput';
import LevelUtils from '../../../utils/LevelUtils';
import CardMasonry from '../../common/card_masonry/CardMasonry';
import StateContext from '../../../providers/StateProvider';
import StateConstants from '../../../constants/StateConstants';
import { useNavigate } from 'react-router-dom';

export default function LevelList() {
    const navigate = useNavigate();
    const { state } = useContext(StateContext);
    const enumStates = StateConstants.EnumStates;
    const commonStates = StateConstants.CommonStates;
    const [searchKey, setSearchKey] = useState('');

    const handleClick = (id) => {
        navigate(`/levels/${id}`);
    };

    return (
        <>
            <div
                className="flex_row full_width padding border_bottom"
                style={{
                    minHeight: 'var(--topbar-height)',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <h2>Levels</h2>
                <div className="flex_row" style={{ flexWrap: 'wrap' }}>
                    <button
                        className="solid"
                        onClick={() => {
                            navigate('/levels/create');
                        }}
                        disabled={state[enumStates.LOADING_ENUMS]}
                    >
                        Create New
                    </button>
                </div>
            </div>
            <div className="flex_column full_width padding">
                <div
                    className="flex_row full_width"
                    style={{ flexWrap: 'wrap' }}
                >
                    <div
                        style={{
                            flex: 1,
                            minWidth: '200px',
                            maxWidth: '400px',
                        }}
                    >
                        <TextInput
                            label="Search Filter"
                            onChange={setSearchKey}
                        />
                    </div>
                </div>
                <CardMasonry
                    loading={state[commonStates.LOADING_LEVELS]}
                    data={LevelUtils.createCardListData(
                        state[commonStates.LEVELS],
                        { searchKey: searchKey }
                    )}
                    onClick={handleClick}
                />
            </div>
        </>
    );
}
