import CardModel from '../models/component_models/CardModel';
import AssetUtils from './AssetUtils';
import TreeViewModel from '../models/component_models/TreeViewModel';
import TypeClassUtils from './TypeClassUtils';
import BaseAttackModel from '../models/data_models/BaseAttackModel';

export default class BaseAttackUtils {
    static createCardData(dictItem) {
        const baseAttack = new BaseAttackModel(dictItem);
        return new CardModel({
            id: baseAttack.id,
            width: 200,
            sections: [
                {
                    type: 'gallery',
                    title: baseAttack.titles.assets,
                    content: {
                        data: AssetUtils.createGalleryData(baseAttack.assets),
                        highlight: true,
                    },
                },
                ...[
                    'projectileAngle',
                    'projectileSpeed',
                    'range',
                    'speed',
                    'damage',
                    'attackType',
                ].map((key) => ({
                    type: 'value',
                    title: baseAttack.titles[key],
                    content: {
                        value: baseAttack[key].toString(),
                    },
                })),
            ],
        });
    }

    static createCardListData(baseAttacks) {
        return baseAttacks.map((dictItem) => this.createCardData(dictItem));
    }

    static createTreeViewNodes(dictObj) {
        const obj = new BaseAttackModel(dictObj);
        return [
            {
                title: obj.titles.damageType,
                type: 'card',
                content: TypeClassUtils.createCardData(obj.damageType),
            },
        ];
    }

    static createTreeViewData(dictObj) {
        return new TreeViewModel({
            root: { type: 'card', content: this.createCardData(dictObj) },
            nodes: this.createTreeViewNodes(dictObj),
        });
    }
}
