import styles from './Masonry.module.css';

export default function Masonry(props) {
    return (
        <div
            className={styles.masonry}
            style={{
                '--column-width': props.columnWidth && `${props.columnWidth}px`,
            }}
        >
            {props.children}
        </div>
    );
}
