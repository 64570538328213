import { useState } from 'react';

import { FaCheck } from 'react-icons/fa';
import { FaCircleXmark, FaImage } from 'react-icons/fa6';

import styles from './Gallery.module.css';
import Tooltip from '../tooltip/Tooltip';

function GalleryImage(props) {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const selectionLayerClassName = `${styles.selection_layer} ${
        props.selected ? styles.selected : ''
    }`.trim();

    return (
        <>
            <Tooltip visible={tooltipVisible} data={props.tooltip} />
            <div
                className={styles.gallery_image}
                onClick={props.onClick}
                onMouseOver={() => props.tooltip && setTooltipVisible(true)}
                onMouseOut={() => props.tooltip && setTooltipVisible(false)}
            >
                {props.selected !== undefined && (
                    <div className={selectionLayerClassName}>
                        <div className={styles.selection_box}>
                            {props.selected ? <FaCheck /> : undefined}
                        </div>
                    </div>
                )}
                {props.overlays &&
                    props.overlays.map((overlay, index) => (
                        <div key={index} className={styles.overlay_layer}>
                            {overlay}
                        </div>
                    ))}
                {error ? (
                    <FaCircleXmark size="30%" color="var(--secondary-color)" />
                ) : !props.src ? (
                    <FaImage size="30%" color="var(--secondary-color)" />
                ) : (
                    <img
                        className={
                            loading && !error ? styles.hidden : undefined
                        }
                        loading="lazy"
                        alt={props.alt}
                        src={props.src}
                        onLoadStart={() => {
                            setLoading(true);
                            setError(false);
                        }}
                        onLoad={() => {
                            setLoading(false);
                        }}
                        onError={() => {
                            setError(true);
                        }}
                    />
                )}
            </div>
        </>
    );
}

/**
 * @param {Object} props
 * @param {GalleryImageModel[]} props.data The shown images data.
 * @param {boolean} [props.loading] The loading state.
 * @param {number[] | string[]} [props.selections] The ids of the selected items.
 * @param {boolean} [props.selectionsFirst] The state of showing the selected items at the top.
 * @param {number} [props.columnWidth] The minimum width of one column.
 * @param {(id: number | string) => void} [props.onClick] The click event of an image.
 */
export default function Gallery(props) {
    const getSortedGalleryData = () => {
        function sortBySelectionFirst(data, selections) {
            const selected = data.filter((item) =>
                selections.includes(item.id)
            );
            const notSelected = data.filter(
                (item) => !selections.includes(item.id)
            );
            return [...selected, ...notSelected];
        }

        return props.data && props.selections && props.selectionsFirst
            ? sortBySelectionFirst(props.data, props.selections)
            : props.data;
    };

    const galleryClassName = `${styles.gallery} ${
        !props.loading && !(props.data && props.data.length > 0)
            ? styles.no_grid
            : ''
    } ${props.highlight ? styles.highlight : ''}`.trim();
    const skeletonImageClassName = `${styles.gallery_image} ${styles.skeleton}`;
    const lastSkeletonImageClassName = `${skeletonImageClassName} ${styles.last}`;

    return (
        <div
            className={galleryClassName}
            style={{
                '--column-width': props.columnWidth && `${props.columnWidth}px`,
            }}
        >
            {props.loading ? (
                <>
                    <div className={skeletonImageClassName}></div>
                    <div className={skeletonImageClassName}></div>
                    <div className={lastSkeletonImageClassName}></div>
                </>
            ) : props.data && props.data.length > 0 ? (
                getSortedGalleryData().map((item, index) => (
                    <GalleryImage
                        key={index}
                        alt={item.alt}
                        src={item.src}
                        overlays={item.overlays}
                        tooltip={item.tooltip}
                        selected={
                            props.selections &&
                            props.selections.includes(item.id)
                        }
                        onClick={() => props.onClick && props.onClick(item.id)}
                    />
                ))
            ) : (
                <p>No Content</p>
            )}
        </div>
    );
}
