import { useEffect, useRef, useState } from 'react';

import styles from './ColorInput.module.css';
import LabelWrapper from '../label_wrapper/LabelWrapper';

export default function ColorInput(props) {
    const [color, setColor] = useState(undefined);
    const inputRef = useRef(null);

    useEffect(() => {
        props.onChange(color);
        // eslint-disable-next-line
    }, [color]);

    const colorInputText = color ? (
        <div className={styles.selection}>
            <div
                className={styles.color_circle}
                style={{
                    backgroundColor: color,
                }}
            ></div>
            <p>{color}</p>
        </div>
    ) : (
        <div className={`${styles.selection} ${styles.empty}`}>
            <p>Not selected.</p>
        </div>
    );

    const colorInput = (
        <div className={styles.color_input}>
            <button
                className={color ? styles.clear : styles.select}
                onClick={() => {
                    if (color) {
                        inputRef.current.value = '';
                        setColor(undefined);
                    } else {
                        inputRef.current.click();
                    }
                }}
            >
                {color ? 'Clear' : 'Select Colour'}
                <input
                    id={props.id}
                    ref={inputRef}
                    type="color"
                    onChange={(e) => {
                        setColor(e.target.value.toUpperCase());
                    }}
                />
            </button>
            {colorInputText}
        </div>
    );

    return props.label ? (
        <LabelWrapper label={props.label}>{colorInput}</LabelWrapper>
    ) : (
        colorInput
    );
}
