import { useContext, useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import Loading from './components/pages/loading_page/Loading';
import Settings from './components/pages/settings_page/Settings';
import Master from './components/pages/master_page/Master';

import Login from './components/pages/login_page/Login';
import NavBar from './components/common/navbar/NavBar';
import AssetPage from './components/pages/asset_page/AssetPage';
import LevelPage from './components/pages/level_page/LevelPage';
import AxiosUtils from './utils/AxiosUtils';
import ErrorUtils from './utils/ErrorUtils';
import AuthService from './services/AuthService';
import EnumService from './services/EnumService';
import StateContext from './providers/StateProvider';
import LocalStorage from './constants/LocalStorageConstants';
import TypeClassPage from './components/pages/type_class_page/TypeClassPage';
import StateConstants from './constants/StateConstants';
import NotificationContext from './providers/NotificationProvider';

export default function App() {
    // Notification
    const { raiseNotification } = useContext(NotificationContext);

    // Navigation
    const navigate = useNavigate();
    const location = useLocation();

    // States
    const enumStates = StateConstants.EnumStates;
    const { setState } = useContext(StateContext);
    const [authChecked, setAuthChecked] = useState(false);

    // Functions
    const checkAuth = async () => {
        setAuthChecked(false);
        if (
            !localStorage[LocalStorage.USER_EMAIL] ||
            !localStorage[LocalStorage.USER_ID] ||
            !localStorage[LocalStorage.USER_NAME] ||
            !localStorage[LocalStorage.ACCESS_TOKEN] ||
            !localStorage[LocalStorage.REFRESH_TOKEN]
        ) {
            localStorage.clear();
            if (location.pathname !== '/login') {
                navigate('/login');
            }
            setAuthChecked(true);
        } else {
            AuthService.refreshAccessTokenIfExpired()
                .then(() => {
                    if (location.pathname === '/login') {
                        navigate('/');
                    }
                })
                .catch(() => {
                    localStorage.clear();
                    if (location.pathname !== '/login') {
                        navigate('/login');
                    }
                })
                .finally(() => {
                    setAuthChecked(true);
                });
        }
    };

    const getEnums = async () => {
        setState(enumStates.LOADING_ENUMS, true);
        await EnumService.getAllEnums()
            .then((response) =>
                setState({
                    [enumStates.ASSET_TYPES]: response.data.assetTypes,
                    [enumStates.ATTACK_TYPES]: response.data.attackTypes,
                    [enumStates.GAME_MODE_TYPES]: response.data.gameModeTypes,
                    [enumStates.MODIFIER_TYPES]: response.data.modifierTypes,
                    [enumStates.STATUS_EFFECT_TYPES]:
                        response.data.statusEffectTypes,
                    [enumStates.USER_ROLES]: response.data.userRoles,
                })
            )
            .catch((error) =>
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                })
            );
        setState(enumStates.LOADING_ENUMS, false);
    };

    // Startup
    useEffect(() => {
        AxiosUtils.setDefaultSettings();
        AxiosUtils.setAuthHeader();
        checkAuth().then(getEnums);
        // eslint-disable-next-line
    }, []);

    return (
        <div className="app">
            {authChecked ? (
                <>
                    {location.pathname !== '/login' ? <NavBar /> : undefined}
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/" element={<Master />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/assets/*" element={<AssetPage />} />
                        <Route
                            path="/type-classes/*"
                            element={<TypeClassPage />}
                        />
                        <Route path="/levels/*" element={<LevelPage />} />
                    </Routes>
                </>
            ) : (
                <Loading />
            )}
        </div>
    );
}
