import { FaSpinner } from 'react-icons/fa';

import styles from './Loading.module.css';

export default function Loading() {
    return (
        <div className={styles.loading_page}>
            <FaSpinner className={styles.loading_spinner} />
        </div>
    );
}
