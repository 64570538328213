import { useContext, useEffect, useState } from 'react';

import ErrorUtils from '../../../utils/ErrorUtils';
import AssetService from '../../../services/AssetService';
import StateContext from '../../../providers/StateProvider';
import TypeClassList from './TypeClassList';
import TypeClassModel from '../../../models/data_models/TypeClassModel';
import StateConstants from '../../../constants/StateConstants';
import TypeClassService from '../../../services/TypeClassService';
import TypeClassInfoModal from './type_class_info_modal/TypeClassInfoModal';
import TypeClassFormModal from './type_class_form_modal/TypeClassFormModal';
import NotificationContext from '../../../providers/NotificationProvider';

export default function TypeClassPage() {
    // Notification
    const { raiseNotification, removeNotification } =
        useContext(NotificationContext);

    // States
    const { state, setState } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const enumStates = StateConstants.EnumStates;
    const typeClassStates = StateConstants.TypeClassStates;
    const [infoModalVisible, setInfoModalVisible] = useState(false);
    const [formModalVisible, setFormModalVisible] = useState(false);
    const [selectedTypeClass, setSelectedTypeClass] = useState(undefined);

    // Functions
    const handleSelection = (id) => {
        setSelectedTypeClass(
            id
                ? state[commonStates.TYPE_CLASSES].find(
                      (item) => item.id === id
                  )
                : undefined
        );
        setInfoModalVisible(id ? true : false);
    };

    const getTypeClasses = async () => {
        setState(commonStates.LOADING_TYPE_CLASSES, true);
        await TypeClassService.getAllTypeClasses()
            .then((response) => {
                setState(commonStates.TYPE_CLASSES, response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setState(commonStates.LOADING_TYPE_CLASSES, false);
    };

    const getAssets = async () => {
        setState(commonStates.LOADING_ASSETS, true);
        await AssetService.getAllAssets()
            .then((response) => setState(commonStates.ASSETS, response.data))
            .catch((error) =>
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                })
            );
        setState(commonStates.LOADING_ASSETS, false);
    };

    const createTypeClass = async () => {
        const notificationId = raiseNotification('Creating type class...', {
            permanent: true,
        });
        const data = await TypeClassModel.createDTO({
            name: state[typeClassStates.NAME],
            descriptionKey: state[typeClassStates.DESC_KEY],
            colour: state[typeClassStates.COLOUR],
            assetIds: state[typeClassStates.ASSET_IDS],
        });
        await TypeClassService.createTypeClass(data)
            .then((response) => {
                removeNotification(notificationId);
                raiseNotification(
                    `Type class was created. ID: ${response.data['id']}`,
                    { type: 'success' }
                );
                getTypeClasses();
            })
            .catch((error) => {
                removeNotification(notificationId);
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
    };

    // Startup
    useEffect(() => {
        getTypeClasses();
        getAssets();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="page scroll_more">
            <div
                className="flex_row full_width padding border_bottom"
                style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <h1>Type Classes</h1>
                <div className="flex_row" style={{ flexWrap: 'wrap' }}>
                    <button
                        className="solid"
                        onClick={() => {
                            setFormModalVisible(true);
                        }}
                        disabled={state[enumStates.LOADING_ENUMS]}
                    >
                        Create New
                    </button>
                </div>
            </div>
            <TypeClassList select={handleSelection} />
            <TypeClassInfoModal
                visible={infoModalVisible}
                setVisible={setInfoModalVisible}
                onClose={() => {
                    setSelectedTypeClass(undefined);
                }}
                typeClass={selectedTypeClass}
            />
            <TypeClassFormModal
                visible={formModalVisible && !state[enumStates.LOADING_ENUMS]}
                setVisible={setFormModalVisible}
                onClose={() => {}}
                createTypeClass={createTypeClass}
            />
        </div>
    );
}
