import { useContext, useState } from 'react';

import TextInput from '../../common/text_input/TextInput';
import CardMasonry from '../../common/card_masonry/CardMasonry';
import StateContext from '../../../providers/StateProvider';
import TypeClassUtils from '../../../utils/TypeClassUtils';
import StateConstants from '../../../constants/StateConstants';

export default function TypeClassList(props) {
    const { state } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const [searchKey, setSearchKey] = useState('');

    return (
        <div className="flex_column full_width padding">
            <div className="flex_row full_width" style={{ flexWrap: 'wrap' }}>
                <div
                    style={{
                        flex: 1,
                        minWidth: '200px',
                        maxWidth: '400px',
                    }}
                >
                    <TextInput label="Search Filter" onChange={setSearchKey} />
                </div>
            </div>
            <CardMasonry
                loading={state[commonStates.LOADING_TYPE_CLASSES]}
                data={TypeClassUtils.createCardListData(
                    state[commonStates.TYPE_CLASSES],
                    { searchKey: searchKey }
                )}
                onClick={props.select}
                columnWidth={200}
            />
        </div>
    );
}
