import DBModel from './DBModel';
import DataUtils from '../../utils/DataUtils';
import AssetModel from './AssetModel';
import SkillModel from './SkillModel';
import ArmorModel from './ArmorModel';
import BaseAttackModel from './BaseAttackModel';

export default class EntityModel extends DBModel {
    constructor({
        name,
        descriptionKey,
        healthPoints,
        baseHPRegen,
        sizeScale,
        colour,
        movementSpeed,
        price,
        teamId,
        baseAttack,
        armor,
        assets,
        skills,
        ...attrs
    }) {
        super(attrs);
        Object.assign(this, {
            name,
            descriptionKey,
            healthPoints,
            baseHPRegen,
            sizeScale,
            colour,
            movementSpeed,
            price,
            teamId,
        });
        this.baseAttack = baseAttack && new BaseAttackModel(baseAttack);
        this.armor = armor && new ArmorModel(armor);
        this.assets = (assets ?? []).map((asset) => new AssetModel(asset));
        this.skills = (skills ?? []).map((skill) => new SkillModel(skill));
    }

    static titles = {
        ...super.titles,
        name: 'Name',
        descriptionKey: 'Desc. Key',
        healthPoints: 'Health Points',
        baseHPRegen: 'Base HP Regen',
        sizeScale: 'Size Scale',
        colour: 'Colour',
        movementSpeed: 'Movement Speed',
        price: 'Price',
        teamId: 'Team ID',
        baseAttack: 'Base Attack',
        armor: 'Armor',
        assets: 'Assets',
        skills: 'Skills',
    };

    static primitiveKeys = [
        ...super.primitiveKeys,
        'name',
        'descriptionKey',
        'healthPoints',
        'baseHPRegen',
        'sizeScale',
        'colour',
        'movementSpeed',
        'price',
        'teamId',
    ];

    static createDTO({
        name,
        descriptionKey,
        healthPoints,
        baseHPRegen,
        sizeScale,
        colour,
        movementSpeed,
        price,
        teamId,
        baseAttack,
        armor,
        assetIds,
        skillIds,
    }) {
        return {
            name: DataUtils.setString(name),
            descriptionKey: DataUtils.setString(descriptionKey),
            healthPoints: DataUtils.setFloat(healthPoints),
            baseHPRegen: DataUtils.setFloat(baseHPRegen),
            sizeScale: DataUtils.setFloat(sizeScale),
            colour: DataUtils.setString(colour),
            movementSpeed: DataUtils.setFloat(movementSpeed),
            price: DataUtils.setFloat(price),
            teamId: DataUtils.setInt(teamId),
            baseAttack: BaseAttackModel.createDTO(baseAttack),
            armor: ArmorModel.createDTO(armor),
            assetIds: DataUtils.setList(assetIds, DataUtils.setInt),
            skillIds: DataUtils.setList(skillIds, DataUtils.setInt),
        };
    }
}
