import DataUtils from './DataUtils';
import CardModel from '../models/component_models/CardModel';
import ModifierModel from '../models/data_models/ModifierModel';
import TreeViewModel from '../models/component_models/TreeViewModel';
import TypeClassUtils from './TypeClassUtils';

class ModifierUtils {
    static createCardData(dictObj) {
        const obj = new ModifierModel(dictObj);
        return new CardModel({
            id: obj.id,
            title: obj.name,
            width: 250,
            sections: [
                ...obj.primitiveKeys.map((key) => ({
                    type: 'value',
                    title: obj.titles[key],
                    content: {
                        value: obj[key] && obj[key].toString(),
                    },
                })),
            ],
        });
    }

    static createSmallCardData(dictItem) {
        const modifier = new ModifierModel(dictItem);
        return new CardModel({
            id: modifier.id,
            title: modifier.name,
            sections: [
                {
                    type: 'value',
                    title: modifier.titles.id,
                    content: {
                        value: modifier.id.toString(),
                    },
                },
            ],
        });
    }

    static createCardListData(modifiers) {
        return modifiers.map((dictItem) => this.createSmallCardData(dictItem));
    }

    static createTreeViewNodes(dictItem) {
        const modifier = new ModifierModel(dictItem);
        return [
            {
                title: modifier.titles.typeClass,
                type: 'card',
                content:
                    modifier.typeClass &&
                    TypeClassUtils.createCardData(modifier.typeClass),
            },
        ];
    }

    static createTreeViewData(dictObj) {
        return new TreeViewModel({
            root: { type: 'card', content: this.createCardData(dictObj) },
            nodes: this.createTreeViewNodes(dictObj),
        });
    }

    // TODO: Delete below functions
    static createModifierDTO(
        modifierName,
        modifierDescriptionKey,
        modifierType,
        modifierExpiration,
        modifierValue,
        modifierPrice,
        modifierAssetIds
    ) {
        return {
            name: DataUtils.setString(modifierName),
            descriptionKey: DataUtils.setString(modifierDescriptionKey),
            modifierType: DataUtils.setString(modifierType),
            expiration: DataUtils.setFloat(modifierExpiration),
            value: DataUtils.setFloat(modifierValue),
            price: DataUtils.setFloat(modifierPrice),
            assetIds: modifierAssetIds,
        };
    }

    static createModifierLabelData(modifier) {
        return [
            { title: 'ID', value: modifier.id },
            { title: 'Modifier Name', value: modifier.name },
            { title: 'Desc. Key', value: modifier.descriptionKey },
            { title: 'Modifier Type', value: modifier.modifierType },
            { title: 'Expiration', value: modifier.expiration },
            { title: 'Value', value: modifier.value },
            { title: 'Price', value: modifier.price },
            { title: 'Created At', value: modifier.createdAt },
        ];
    }
}

export default ModifierUtils;
