import CardModel from '../models/component_models/CardModel';
import AssetUtils from './AssetUtils';
import AssetModel from '../models/data_models/AssetModel';
import TypeClassModel from '../models/data_models/TypeClassModel';
import GalleryImageModel from '../models/component_models/GalleryImageModel';
import { GalleryPillLabel } from '../components/common/gallery_overlays/GalleryOverlays';

export default class TypeClassUtils {
    static filterTypeClasses(typeClasses, searchKey) {
        const filterKeys = [
            'id',
            'name',
            'descriptionKey',
            'colour',
            'createdAt',
        ];
        const regex = new RegExp(searchKey.toLowerCase());

        return typeClasses.filter((typeClass) => {
            const searchTypeClassValues = Object.entries(typeClass)
                .filter(([key]) => filterKeys.includes(key))
                .map(([_, value]) => value);

            const isAnyValueMatch = searchTypeClassValues
                .map((value) => regex.test(value.toString().toLowerCase()))
                .some(Boolean);

            return isAnyValueMatch;
        });
    }

    static createGalleryImageData(dictObj) {
        const obj = new TypeClassModel(dictObj);
        const sortedAssets = AssetUtils.sortByAssetType(obj.assets);
        const shownAsset =
            sortedAssets && sortedAssets[0]
                ? new AssetModel(sortedAssets[0])
                : {};
        return new GalleryImageModel({
            id: obj.id,
            alt: shownAsset.assetReference,
            src: shownAsset.imageUrl,
            tooltip: obj.primitiveKeys.map((key) => ({
                title: obj.titles[key],
                value: obj[key],
            })),
            overlays: [<GalleryPillLabel text={obj.name} />],
        });
    }

    static createCardData(dictItem) {
        const typeClass = new TypeClassModel(dictItem);
        return new CardModel({
            id: typeClass.id,
            title: typeClass.name,
            color: typeClass.colour,
            width: 250,
            sections: [
                {
                    type: 'value',
                    title: typeClass.titles.id,
                    content: {
                        value: typeClass.id.toString(),
                    },
                },
                {
                    type: 'gallery',
                    title: 'Assets',
                    content: {
                        data: AssetUtils.createGalleryData(typeClass.assets),
                        highlight: true,
                    },
                },
                ...['descriptionKey', 'colour', 'createdAt'].map((key) => ({
                    type: 'value',
                    title: typeClass.titles[key],
                    content: {
                        value: typeClass[key].toString(),
                    },
                })),
            ],
        });
    }

    static createCardListData(typeClasses, { searchKey } = {}) {
        const filteredTypeClasses = searchKey
            ? this.filterTypeClasses(typeClasses, searchKey)
            : typeClasses;
        return filteredTypeClasses.map((dictItem) =>
            this.createCardData(dictItem)
        );
    }
}
