export default class ColorUtils {
    static hexToRgb(hex) {
        hex = hex.replace('#', '');

        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return { r, g, b };
    }

    static calculateLuminance(hex) {
        const { r, g, b } = this.hexToRgb(hex);

        const normalize = (value) => {
            value /= 255;
            return value <= 0.03928
                ? value / 12.92
                : Math.pow((value + 0.055) / 1.055, 2.4);
        };

        const R = normalize(r);
        const G = normalize(g);
        const B = normalize(b);

        return 0.2126 * R + 0.7152 * G + 0.0722 * B;
    }
}
