import { useEffect, useState } from 'react';

import { FaCircleXmark } from 'react-icons/fa6';

import Modal from '../../../common/modal/Modal';
import styles from './AssetInfoModal.module.css';
import TextBox from '../../../common/text_box/TextBox';
import AssetModel from '../../../../models/data_models/AssetModel';

function AssetImage(props) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    return (
        <div className={styles.asset_image}>
            {error ? (
                <FaCircleXmark size="30%" color="var(--secondary-color)" />
            ) : (
                <img
                    className={loading && !error ? styles.hidden : undefined}
                    alt={props.alt}
                    src={props.src}
                    onLoadStart={() => {
                        setLoading(true);
                        setError(false);
                    }}
                    onLoad={() => {
                        setLoading(false);
                    }}
                    onError={() => {
                        setError(true);
                    }}
                />
            )}
        </div>
    );
}

export default function AssetInfoModal(props) {
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);

    useEffect(() => {
        setConfirmDeleteVisible(false);
    }, [props.visible]);

    return (
        <>
            <Modal
                title="Asset Info"
                visible={props.visible}
                setVisible={props.setVisible}
                onClose={props.onClose}
                draggable
            >
                <div className={styles.info_modal}>
                    <AssetImage
                        alt={props.asset && props.asset.assetReference}
                        src={props.asset && props.asset.imageUrl}
                    />
                    <TextBox label={AssetModel.titles.id} wrap>
                        {props.asset && props.asset.id}
                    </TextBox>
                    <TextBox label={AssetModel.titles.assetReference} wrap>
                        {props.asset && props.asset.assetReference}
                    </TextBox>
                    <TextBox label={AssetModel.titles.assetType} wrap>
                        {props.asset && props.asset.assetType}
                    </TextBox>
                    <TextBox label={AssetModel.titles.createdAt} wrap>
                        {props.asset && props.asset.createdAt}
                    </TextBox>
                    <div className={styles.buttons}>
                        <button disabled className="solid">
                            Edit
                        </button>
                        <button
                            className="solid danger"
                            onClick={() => setConfirmDeleteVisible(true)}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={confirmDeleteVisible}
                setVisible={setConfirmDeleteVisible}
                noCloseIcon
            >
                <div className={styles.confirm_delete}>
                    <div className={styles.info}>
                        {props.asset &&
                            new AssetModel(props.asset)
                                .getTitleValueMapping()
                                .map(({ title, value }, index) => (
                                    <p key={index}>
                                        <b>{title}:</b> {value}
                                    </p>
                                ))}
                    </div>
                    <div className={styles.message}>
                        <p>Are you sure you want to delete the asset?</p>
                        <div className={styles.buttons}>
                            <button
                                onClick={() => setConfirmDeleteVisible(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="solid danger"
                                onClick={() =>
                                    props.deleteAsset(props.asset.id)
                                }
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
