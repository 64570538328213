export default class CardModel {
    constructor({
        id = undefined,
        title = undefined,
        color = undefined,
        borderColor = undefined,
        label = undefined,
        width = undefined,
        sections = undefined,
        toolbar = undefined,
        onClick = undefined,
    }) {
        Object.assign(this, {
            id,
            title,
            color,
            borderColor,
            label,
            width,
            toolbar,
            onClick,
        });
        this.sections =
            sections &&
            sections.map((section) => new CardSectionModel(section));
    }
}

class CardSectionModel {
    constructor({ type = 'value', title, content = undefined }) {
        this.type = type;
        this.title = title;
        this.content = this.#setContent(type, content);
    }

    #setContent(type, content) {
        return type === 'gallery'
            ? { data: content.data, highlight: content.highlight }
            : type === 'json'
            ? {
                  data: content.data.map((item) => new CardSectionModel(item)),
              }
            : type === 'value'
            ? { value: content.value }
            : undefined;
    }
}
