import axios from 'axios';

import AuthService from './AuthService';

export default class EnumService {
    static async getAllEnums() {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.get('/enums');
        return response;
    }
}
