import Pill from '../pill/Pill';
import styles from './GalleryOverlays.module.css';

export function GalleryPillLabel(props) {
    const labelClassName = `${styles.gallery_pill_label} ${
        props.top ? styles.top : ''
    } ${props.left ? styles.left : ''}`.trim();

    return (
        <div className={labelClassName}>
            <Pill
                text={props.text}
                color={props.color}
                circular={props.circular}
            />
        </div>
    );
}

export function GalleryImageBorder(props) {
    return (
        <div
            className={styles.gallery_image_border}
            style={{ borderColor: props.color }}
        ></div>
    );
}
