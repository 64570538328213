import ColorUtils from '../../../utils/ColorUtils';
import styles from './Pill.module.css';

export default function Pill(props) {
    const pillClassName = `${styles.pill} ${
        props.color ? styles.colored : ''
    } ${props.circular ? styles.circular : ''} ${
        props.onClick ? styles.clickable : ''
    }`.trim();

    const fgColor =
        props.color && ColorUtils.calculateLuminance(props.color) > 0.5
            ? 'black'
            : 'white';

    return (
        <div
            className={pillClassName}
            style={{
                '--pill-font-size': props.fontSize && `${props.fontSize}px`,
                '--pill-bg-color': props.color,
                '--pill-fg-color': fgColor,
            }}
            onClick={props.onClick}
        >
            {props.text && <p>{props.text}</p>}
            {props.children}
        </div>
    );
}
