import { useContext, useEffect, useState } from 'react';

import AssetList from './AssetList';
import ErrorUtils from '../../../utils/ErrorUtils';
import AssetModel from '../../../models/data_models/AssetModel';
import AssetService from '../../../services/AssetService';
import StateContext from '../../../providers/StateProvider';
import StateConstants from '../../../constants/StateConstants';
import AssetInfoModal from './asset_info_modal/AssetInfoModal';
import AssetFormModal from './asset_form_modal/AssetFormModal';
import NotificationContext from '../../../providers/NotificationProvider';

export default function AssetPage() {
    // Notification
    const { raiseNotification, removeNotification } =
        useContext(NotificationContext);

    // States
    const { state, setState } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const enumStates = StateConstants.EnumStates;
    const assetStates = StateConstants.AssetStates;
    const [infoModalVisible, setInfoModalVisible] = useState(false);
    const [formModalVisible, setFormModalVisible] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(undefined);

    // Functions
    const handleSelection = (id) => {
        setSelectedAsset(
            id
                ? state[commonStates.ASSETS].find((item) => item.id === id)
                : undefined
        );
        setInfoModalVisible(id ? true : false);
    };

    const getAssets = async () => {
        setState(commonStates.LOADING_ASSETS, true);
        await AssetService.getAllAssets()
            .then((response) => setState(commonStates.ASSETS, response.data))
            .catch((error) =>
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                })
            );
        setState(commonStates.LOADING_ASSETS, false);
    };

    const createAsset = async () => {
        const notificationId = raiseNotification('Creating asset...', {
            permanent: true,
        });
        const data = await AssetModel.createDTO({
            assetType: state[assetStates.TYPE],
            assetReference: state[assetStates.REFERENCE],
            image: state[assetStates.IMAGE],
        });
        AssetService.createAsset(data)
            .then((response) => {
                removeNotification(notificationId);
                raiseNotification(
                    `Asset was created. ID: ${response.data['id']}`,
                    { type: 'success' }
                );
                getAssets();
            })
            .catch((error) => {
                removeNotification(notificationId);
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
    };

    const deleteAsset = (assetId) => {
        const notificationId = raiseNotification('Deleting asset...', {
            permanent: true,
        });
        AssetService.deleteAsset(assetId)
            .then(() => {
                handleSelection(undefined);
                removeNotification(notificationId);
                raiseNotification('Asset was deleted.', {
                    type: 'success',
                });
            })
            .catch((error) => {
                handleSelection(undefined);
                removeNotification(notificationId);
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            })
            .finally(() => {
                getAssets();
            });
    };

    // Startup
    useEffect(() => {
        getAssets();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="page scroll_more">
            <div
                className="flex_row full_width padding border_bottom"
                style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <h1>Assets</h1>
                <div className="flex_row" style={{ flexWrap: 'wrap' }}>
                    <button
                        className="solid"
                        onClick={() => setFormModalVisible(true)}
                        disabled={state[enumStates.LOADING_ENUMS]}
                    >
                        Create New
                    </button>
                </div>
            </div>
            <AssetList select={handleSelection} />
            <AssetInfoModal
                visible={infoModalVisible}
                setVisible={setInfoModalVisible}
                onClose={() => setSelectedAsset(undefined)}
                asset={selectedAsset}
                deleteAsset={deleteAsset}
            />
            <AssetFormModal
                visible={formModalVisible && !state[enumStates.LOADING_ENUMS]}
                setVisible={setFormModalVisible}
                onClose={() => {}}
                createAsset={createAsset}
            />
        </div>
    );
}
