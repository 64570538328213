import Modal from '../../../common/modal/Modal';
import styles from './TypeClassInfoModal.module.css';
import Gallery from '../../../common/gallery/Gallery';
import TextBox from '../../../common/text_box/TextBox';
import AssetUtils from '../../../../utils/AssetUtils';
import TypeClassModel from '../../../../models/data_models/TypeClassModel';

export default function TypeClassInfoModal(props) {
    return (
        <>
            <Modal
                title="Type Class Info"
                visible={props.visible}
                setVisible={props.setVisible}
                onClose={props.onClose}
                draggable
            >
                <div className={styles.info_modal}>
                    <div className={styles.gallery}>
                        <p>Assets</p>
                        <Gallery
                            data={
                                props.typeClass &&
                                AssetUtils.createGalleryData(
                                    props.typeClass.assets
                                )
                            }
                        />
                    </div>
                    <TextBox label={TypeClassModel.titles.id} wrap>
                        {props.typeClass && props.typeClass.id}
                    </TextBox>
                    <TextBox label={TypeClassModel.titles.name} wrap>
                        {props.typeClass && props.typeClass.name}
                    </TextBox>
                    <TextBox label={TypeClassModel.titles.descriptionKey} wrap>
                        {props.typeClass && props.typeClass.descriptionKey}
                    </TextBox>
                    <TextBox label={TypeClassModel.titles.colour} wrap>
                        {props.typeClass && props.typeClass.colour && (
                            <>
                                <div
                                    className={styles.color_circle}
                                    style={{
                                        backgroundColor: props.typeClass.colour,
                                    }}
                                ></div>
                                <p>{props.typeClass.colour}</p>
                            </>
                        )}
                    </TextBox>
                    <TextBox label={TypeClassModel.titles.createdAt} wrap>
                        {props.typeClass && props.typeClass.createdAt}
                    </TextBox>
                    <div className={styles.buttons}>
                        <button disabled className="solid">
                            Edit
                        </button>
                        <button className="solid danger">Delete</button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
