import CardModel from '../models/component_models/CardModel';
import ArmorModel from '../models/data_models/ArmorModel';
import TreeViewModel from '../models/component_models/TreeViewModel';
import TypeClassUtils from './TypeClassUtils';

export default class ArmorUtils {
    static createCardData(dictItem) {
        const armor = new ArmorModel(dictItem);
        return new CardModel({
            id: armor.id,
            width: 100,
            sections: [
                ...['value'].map((key) => ({
                    type: 'value',
                    title: armor.titles[key],
                    content: {
                        value: armor[key].toString(),
                    },
                })),
            ],
        });
    }

    static createCardListData(armors) {
        return armors.map((dictItem) => this.createCardData(dictItem));
    }

    static createTreeViewNodes(dictItem) {
        const armor = new ArmorModel(dictItem);
        const typeClassCardData = TypeClassUtils.createCardData(armor.type);
        return [
            {
                title: armor.titles.type,
                type: 'card',
                content: typeClassCardData,
            },
        ];
    }

    static createTreeViewData(dictObj) {
        return new TreeViewModel({
            root: { type: 'card', content: this.createCardData(dictObj) },
            nodes: this.createTreeViewNodes(dictObj),
        });
    }
}
