import { useState } from 'react';

import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import styles from './Drawer.module.css';
import LabelWrapper from '../label_wrapper/LabelWrapper';

function Drawer(props) {
    const [open, setOpen] = useState(false);

    const contentClassName = `${styles.content} ${
        open ? '' : styles.hidden
    }`.trim();

    const drawer = (
        <div id={props.id} className={styles.drawer}>
            <div
                className={styles.header}
                onClick={() => {
                    setOpen((prevState) => !prevState);
                }}
            >
                <p>{props.text}</p>
                <div className={styles.arrow}>
                    {open ? <FaChevronUp /> : <FaChevronDown />}
                </div>
            </div>
            <div className={contentClassName}>{props.children}</div>
        </div>
    );

    return props.label ? (
        <LabelWrapper label={props.label}>{drawer}</LabelWrapper>
    ) : (
        drawer
    );
}

export default Drawer;
