import FileUtils from './FileUtils';

class DataUtils {
    static setFloat(value, defaultValue = null) {
        return parseFloat(value) ? parseFloat(value) : defaultValue;
    }

    static setInt(value, defaultValue = null) {
        return parseInt(value) ? parseInt(value) : defaultValue;
    }

    static setString(value, defaultValue = null) {
        return value ? String(value) : defaultValue;
    }

    static setBool(value, defaultValue = null) {
        return value ? Boolean(value) : defaultValue;
    }

    static async setFile(value, defaultValue = null) {
        let file = defaultValue;
        if (value) {
            try {
                file = await FileUtils.convertFileToBase64(value);
            } catch (error) {
                throw new Error(`Error during file conversion: ${error}`);
            }
        }
        return file;
    }

    static setList(values, setFunction, defaultValue = []) {
        return values
            ? values.map((value) => setFunction(value))
            : defaultValue;
    }

    static createDataWithCount(items, ids) {
        const uniqueIds = Array.from(new Set(ids));
        return uniqueIds.map((id) => {
            const foundData = items.find((item) => item.id === id);
            return {
                ...(foundData ? foundData : {}),
                count: ids.filter((item) => item === id).length,
                id: id,
            };
        });
    }
}

export default DataUtils;
