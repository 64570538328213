import axios from 'axios';

import AuthService from './AuthService';

export default class WaveService {
    static async createWave(levelId, data) {
        console.log(data);
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.post(`/levels/${levelId}/waves`, data);
        return response;
    }

    static async updateWave(levelId, waveId, data) {
        console.log(data);
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.put(
            `/levels/${levelId}/waves/${waveId}`,
            data
        );
        return response;
    }
}
