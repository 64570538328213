import React, { useContext, useEffect, useState } from 'react';

import Modal from '../../../common/modal/Modal';
import styles from './WaveFormModal.module.css';
import Drawer from '../../../common/drawer/Drawer';
import Gallery from '../../../common/gallery/Gallery';
import TextInput from '../../../common/text_input/TextInput';
import WaveModel from '../../../../models/data_models/WaveModel';
import ErrorUtils from '../../../../utils/ErrorUtils';
import EntityUtils from '../../../../utils/EntityUtils';
import WaveService from '../../../../services/WaveService';
import CardMasonry from '../../../common/card_masonry/CardMasonry';
import StateContext from '../../../../providers/StateProvider';
import StateConstants from '../../../../constants/StateConstants';
import NotificationContext from '../../../../providers/NotificationProvider';
import { useNavigate, useParams } from 'react-router-dom';

export default function WaveFormModal(props) {
    // Navigation
    const navigate = useNavigate();
    const params = useParams();

    // Notification
    const { raiseNotification, removeNotification } =
        useContext(NotificationContext);

    // States
    const { state, setState } = useContext(StateContext);
    const waveStates = StateConstants.WaveStates;
    const commonStates = StateConstants.CommonStates;
    const [searchValue, setSearchValue] = useState('');

    // Startup
    useEffect(() => {
        if (props.mode === 'edit' && !state[commonStates.LOADING_LEVELS]) {
            const waveDict = state[commonStates.LEVELS]
                .find((level) => level.id === params.levelId)
                ?.waves?.find((wave) => wave.id.toString() === params.waveId);
            setState({
                [waveStates.NAME]: waveDict.name,
                [waveStates.DESC_KEY]: waveDict.descriptionKey,
                [waveStates.DURATION]: waveDict.duration,
                [waveStates.PRIORITY]: waveDict.priority,
                [waveStates.MODIFIER_IDS]: waveDict.modifierIds,
                [waveStates.MODIFIER_GROUP_IDS]: waveDict.modifierGroupIds,
                [waveStates.WAVE_ENTITIES]: waveDict.waveEntities.map(
                    (waveEntity) => ({
                        entityId: waveEntity.entity.id,
                        count: waveEntity.count,
                    })
                ),
            });
        }
        // eslint-disable-next-line
    }, [props.mode, state[commonStates.LOADING_ENTITIES]]);

    const handleSelectWaveEntity = (entityId) => {
        const existingWaveEntity = state[waveStates.WAVE_ENTITIES].find(
            (waveEntity) => waveEntity.entityId === entityId
        );
        setState(
            waveStates.WAVE_ENTITIES,
            existingWaveEntity
                ? state[waveStates.WAVE_ENTITIES].filter(
                      (waveEntity) => waveEntity.entityId !== entityId
                  )
                : [...state[waveStates.WAVE_ENTITIES], { entityId, count: 1 }]
        );
    };

    const createWave = async () => {
        const notificationId = raiseNotification('Creating wave...', {
            permanent: true,
        });
        const data = WaveModel.createDTO({
            name: state[waveStates.NAME],
            descriptionKey: state[waveStates.DESC_KEY],
            priority: state[waveStates.PRIORITY],
            duration: state[waveStates.DURATION],
            modifierIds: state[waveStates.MODIFIER_IDS],
            modifierGroupIds: state[waveStates.MODIFIER_GROUP_IDS],
            waveEntities: state[waveStates.WAVE_ENTITIES],
        });
        WaveService.createWave(params.levelId, data)
            .then((response) => {
                removeNotification(notificationId);
                raiseNotification(
                    `Wave was created. ID: ${response.data['id']}`,
                    { type: 'success' }
                );
                props.getLevels();
                navigate(`/levels/${params.levelId}`);
            })
            .catch((error) => {
                removeNotification(notificationId);
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
    };

    const updateWave = async () => {
        const notificationId = raiseNotification(`Updating wave...`, {
            permanent: true,
        });
        const data = WaveModel.createDTO({
            name: state[waveStates.NAME],
            descriptionKey: state[waveStates.DESC_KEY],
            priority: state[waveStates.PRIORITY],
            duration: state[waveStates.DURATION],
            modifierIds: state[waveStates.MODIFIER_IDS],
            modifierGroupIds: state[waveStates.MODIFIER_GROUP_IDS],
            waveEntities: state[waveStates.WAVE_ENTITIES],
        });
        WaveService.updateWave(params.levelId, params.waveId, data)
            .then((response) => {
                removeNotification(notificationId);
                raiseNotification(
                    `Wave was updated. ID: ${response.data['id']}`,
                    { type: 'success' }
                );
                props.getLevels();
                navigate(`/levels/${params.levelId}`);
            })
            .catch((error) => {
                removeNotification(notificationId);
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
    };

    const handleSetCountWaveEntity = (entityId, count) => {
        if (count > 0) {
            const waveEntities = state[waveStates.WAVE_ENTITIES].map(
                (waveEntity) => {
                    return waveEntity.entityId === entityId
                        ? { entityId, count }
                        : waveEntity;
                }
            );
            setState(waveStates.WAVE_ENTITIES, waveEntities);
        } else {
            setState(
                waveStates.WAVE_ENTITIES,
                state[waveStates.WAVE_ENTITIES].filter(
                    (waveEntity) => waveEntity.entityId !== entityId
                )
            );
        }
    };

    const waveEntityIds = [
        ...state[waveStates.WAVE_ENTITIES].map(
            (waveEntity) => waveEntity.entityId
        ),
    ];

    return (
        <Modal
            title={props.mode === 'edit' ? 'Edit Wave' : 'Create Wave'}
            visible={props.visible}
            setVisible={props.setVisible}
            onClose={props.onClose}
        >
            <div className={styles.form_modal}>
                <TextInput
                    label={`${WaveModel.titles.name} (String)`}
                    value={state[waveStates.NAME]}
                    onChange={(value) => setState(waveStates.NAME, value)}
                />
                <TextInput
                    label={`${WaveModel.titles.descriptionKey} (String)`}
                    value={state[waveStates.DESC_KEY]}
                    onChange={(value) => setState(waveStates.DESC_KEY, value)}
                />
                <TextInput
                    type="int"
                    label={`${WaveModel.titles.priority} (Int)`}
                    value={state[waveStates.PRIORITY]}
                    onChange={(value) => setState(waveStates.PRIORITY, value)}
                />
                <TextInput
                    type="float"
                    label={`${WaveModel.titles.duration} (Float)`}
                    value={state[waveStates.DURATION]}
                    onChange={(value) => setState(waveStates.DURATION, value)}
                />
                <Drawer
                    label="Entities"
                    text={
                        state[waveStates.WAVE_ENTITIES].length > 0
                            ? `Selected ${
                                  state[waveStates.WAVE_ENTITIES].length
                              } entities(s).`
                            : 'Not selected.'
                    }
                >
                    <div className="flex_column full_width padding">
                        {waveEntityIds.length > 0 && (
                            <CardMasonry
                                loading={state[commonStates.LOADING_ENTITIES]}
                                data={EntityUtils.createCardListData(
                                    state[waveStates.WAVE_ENTITIES].map(
                                        ({ entityId, count }) => ({
                                            ...(state[
                                                commonStates.ENTITIES
                                            ].find(
                                                (entity) =>
                                                    entity.id === entityId
                                            ) ?? {}),
                                            count: count,
                                        })
                                    )
                                )}
                                setCount={handleSetCountWaveEntity}
                            />
                        )}
                        <div
                            className="flex_row full_width"
                            style={{ flexWrap: 'wrap' }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    minWidth: '200px',
                                    maxWidth: '400px',
                                }}
                            >
                                <TextInput
                                    label="Search Filter"
                                    onChange={setSearchValue}
                                />
                            </div>
                        </div>
                        <Gallery
                            loading={state[commonStates.LOADING_ENTITIES]}
                            data={EntityUtils.createGalleryData(
                                EntityUtils.filterByValue(
                                    state[commonStates.ENTITIES],
                                    searchValue
                                )
                            )}
                            onClick={handleSelectWaveEntity}
                            selections={state[waveStates.WAVE_ENTITIES].map(
                                (waveEntity) => waveEntity.entityId
                            )}
                            selectionsFirst
                        />
                    </div>
                </Drawer>
                <div className="page_row right">
                    <button
                        className="solid"
                        onClick={
                            props.mode === 'create' ? createWave : updateWave
                        }
                    >
                        {props.mode === 'create' ? 'Create Wave' : 'Save Wave'}
                    </button>
                </div>
            </div>
        </Modal>
    );
}
