import DBModel from './DBModel';
import DataUtils from '../../utils/DataUtils';
import TypeClassModel from './TypeClassModel';

export default class ArmorModel extends DBModel {
    constructor({ value, type, ...attrs }) {
        super(attrs);
        this.value = value;
        this.type = new TypeClassModel(type);
    }

    static titles = {
        ...super.titles,
        value: 'Value',
        type: 'Type',
    };

    static createDTO({ value, typeId }) {
        return {
            value: DataUtils.setFloat(value),
            typeId: DataUtils.setInt(typeId),
        };
    }
}
