import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { FaBars, FaUserAlt } from 'react-icons/fa';
import { FaGear, FaRightFromBracket } from 'react-icons/fa6';

import Modal from '../modal/Modal';
import styles from './NavBar.module.css';
import LocalStorage from '../../../constants/LocalStorageConstants';

function SideBarProfile(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    return (
        <div className={styles.sidebar_profile}>
            <div className={styles.profile_picture}>
                {loading && (
                    <div className={styles.layer}>
                        <FaUserAlt size="50%" color="var(--secondary-color)" />
                    </div>
                )}
                <img
                    className={loading ? styles.hidden : undefined}
                    alt="profilePicture"
                    onLoad={() => {
                        setLoading(false);
                    }}
                />
            </div>
            <div className={styles.profile_text}>
                <p className={styles.user}>
                    {localStorage[LocalStorage.USER_NAME]}
                </p>
                <p className={styles.role}>{`(${
                    localStorage[LocalStorage.USER_ROLE]
                })`}</p>
            </div>
            <div className={styles.buttons}>
                <button onClick={props.logOut}>
                    <FaRightFromBracket />
                </button>
                <button
                    disabled={location.pathname === '/settings'}
                    onClick={() => navigate('/settings')}
                >
                    <FaGear />
                </button>
            </div>
        </div>
    );
}

function SideBar(props) {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className={styles.sidebar} onClick={(e) => e.stopPropagation()}>
            <SideBarProfile logOut={props.logOut} />
            <div className={styles.buttons}>
                <button
                    disabled={location.pathname === '/'}
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    Master
                </button>
                <button
                    disabled={location.pathname === '/assets'}
                    onClick={() => {
                        navigate('/assets');
                    }}
                >
                    Assets
                </button>
                <button
                    disabled={location.pathname === '/type-classes'}
                    onClick={() => {
                        navigate('/type-classes');
                    }}
                >
                    Type Classes
                </button>
                {/* <button
                    disabled={location.pathname === '/skills'}
                    onClick={() => {
                        navigate('/skills');
                    }}
                >
                    Skills
                </button>
                <button
                    disabled={location.pathname === '/modifiers'}
                    onClick={() => {
                        navigate('/modifiers');
                    }}
                >
                    Modifiers
                </button>
                <button
                    disabled={location.pathname === '/entities'}
                    onClick={() => {
                        navigate('/entities');
                    }}
                >
                    Entities
                </button> */}
                <button
                    disabled={location.pathname === '/levels'}
                    onClick={() => {
                        navigate('/levels');
                    }}
                >
                    Levels
                </button>
            </div>
        </div>
    );
}

function ConfirmLogoutModal(props) {
    const navigate = useNavigate();

    const logOut = () => {
        localStorage.clear();
        navigate('/login');
    };

    const cancelLogOut = () => {
        props.setVisible(false);
    };

    return (
        <Modal
            visible={props.visible}
            setVisible={props.setVisible}
            noCloseIcon
        >
            <div className={styles.confirm_logout}>
                <p>Are you sure you want to log out?</p>
                <div className={styles.buttons}>
                    <button onClick={cancelLogOut}>Cancel</button>
                    <button className="solid danger" onClick={logOut}>
                        Log out
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default function NavBar() {
    const location = useLocation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [confirmLogOutVisible, setConfirmLogOutVisible] = useState(false);

    useEffect(() => {
        setDrawerOpen(false);
    }, [location]);

    const sidebarBackgroundClassName = drawerOpen
        ? styles.sidebar_background
        : `${styles.sidebar_background} ${styles.hidden}`;

    const openConfirmLogoutModal = () => {
        setConfirmLogOutVisible(true);
    };

    return (
        <>
            <div className={styles.topbar}>
                <button
                    className={styles.drawer_button}
                    onClick={() => {
                        setDrawerOpen(true);
                    }}
                >
                    <FaBars />
                </button>
            </div>
            <div
                className={sidebarBackgroundClassName}
                onClick={() => {
                    setDrawerOpen(false);
                }}
            >
                <SideBar logOut={openConfirmLogoutModal} />
            </div>
            <SideBar logOut={openConfirmLogoutModal} />
            <ConfirmLogoutModal
                visible={confirmLogOutVisible}
                setVisible={setConfirmLogOutVisible}
            />
        </>
    );
}
