export default class StringUtils {
    static camelCaseToTitle(string) {
        return string
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (match) => match.toUpperCase())
            .trim();
    }

    static getInitialsFromCamelCase(string) {
        return string
            .match(/([A-Z])|(^[a-z])/g)
            .join('')
            .toUpperCase();
    }
}
